import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Login from "./pages/login/Login";
import Register from "./pages/login/Register";
import Reset from "./pages/login/Reset";
import Logout from "./pages/login/Logout";
import Dashboard from "./pages/DashBoard";
import ProductList from "./pages/product/ProductList";
import ProductDetails from "./pages/product/ProductDetails";
import Coupon from "./pages/coupon/coupon";
import MyProfiler from "./pages/profile/MyProfile";
import OrderDetails from "./pages/order/OrderDetails";
import OrderPage from "./pages/order/OrdersPage";
import Navbar from "./pages/Navbar";
import Header from "./pages/Header";
import Address from "./pages/address/Address";
import AddressBox from "./pages/address/AddressBox";
import NewAddress from "./pages/address/NewAddress";
import Wishlist from "./pages/cart/Wishlist";
import Notification from "./pages/profile/Notification";
import Cartpage from "./pages/cart/Cartpage";
import Footer from "./pages/Footer"
import OrderCheckoutSection from "./pages/checkout/OrderCheckoutSection";
import Checkoutpage from "./pages/checkout/Checkoutpage";
import Demo from './pages/product/Demo'



import AuthContext, { Auth } from "./Context/AuthContext";
import { ProductContext } from "./Context/ProductContext";
import { AddressContext } from "./Context/AddressContext";
import { CartContext } from "./Context/CartContext";
import { SearchContext } from "./Context/SearchContext";

// Layout Components
const NoLayout = ({ children }) => <>{children}</>;
const NavbarLayout = ({ children }) => (
  <>
    <Navbar />
    {children}
    <Footer />

  </>
);
const DashboardLayout = ({ children }) => (
  <>
    <Header />
    <div className="pt-10"></div>
    <Navbar isSearchOn={true} clearNavbarStake={true} />
    {children}
    <Footer />

  </>
);

const PrivateRoute = ({ children }) => {
  const AuthUserContext = useContext(Auth)
  const navigate = useNavigate()
  console.log(AuthUserContext.isLoggedIn);
  if (AuthUserContext.customerId === 0) {
    navigate("/login")
  } else {
    return children;
  }
};





const AppComponent = () => (
  <>

    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<NoLayout><Login /></NoLayout>} />
      <Route path="/register" element={<NoLayout><Register /></NoLayout>} />
      <Route path="/reset" element={<NoLayout><Reset /></NoLayout>} />
      <Route path="/Logout" element={<NoLayout><Logout /></NoLayout>} />

      {/* Routes with Navbar */}
      <Route path="/" element={<DashboardLayout><Dashboard /></DashboardLayout>} />
      <Route path="/ProductList" element={<NavbarLayout><ProductList /></NavbarLayout>} />
      <Route path="/demo" element={<NavbarLayout><Demo /></NavbarLayout>} />
      <Route path="/ProductDetails" element={<NavbarLayout><ProductDetails /></NavbarLayout>} />
      <Route path="/MyProfiler" element={<NavbarLayout><MyProfiler /></NavbarLayout>} />
      <Route path="/Coupon" element={<NavbarLayout><Coupon /></NavbarLayout>} />
      <Route path="/WishList" element={<NavbarLayout><Wishlist /></NavbarLayout>} />
      <Route path="/Notification" element={<NavbarLayout><Notification /></NavbarLayout>} />
      <Route path="/Navbar" element={<Navbar />} />

      {/* Protected Routes */}
      <Route path="/OrderPage" element={<PrivateRoute><NavbarLayout><OrderPage /></NavbarLayout></PrivateRoute>} />
      <Route path="/OrderDetails" element={<PrivateRoute><NavbarLayout><OrderDetails /></NavbarLayout></PrivateRoute>} />
      <Route path="/Address" element={<PrivateRoute><NavbarLayout><Address /></NavbarLayout></PrivateRoute>} />
      <Route path="/AddressBox" element={<PrivateRoute><NavbarLayout><AddressBox /></NavbarLayout></PrivateRoute>} />
      <Route path="/NewAddress" element={<PrivateRoute><NavbarLayout><NewAddress /></NavbarLayout></PrivateRoute>} />
      <Route path="/Cartpage" element={<PrivateRoute><NavbarLayout><Cartpage /></NavbarLayout></PrivateRoute>} />
      <Route path="/OrderCheckoutSection" element={<PrivateRoute><NavbarLayout><OrderCheckoutSection /></NavbarLayout></PrivateRoute>} />
      <Route path="/Checkoutpage" element={<PrivateRoute><NavbarLayout><Checkoutpage /></NavbarLayout></PrivateRoute>} />

      {/* Fallback for undefined routes */}
      <Route path="*" element={<NavbarLayout><h3>Page not found</h3></NavbarLayout>} />
    </Routes>

  </>

);

const App = () => (
  <AuthContext>
    <ProductContext>
      <AddressContext>
        <CartContext>
          <SearchContext>
            <BrowserRouter>
              <AppComponent />
            </BrowserRouter>
          </SearchContext>
        </CartContext>
      </AddressContext>
    </ProductContext>
  </AuthContext>
);

export default App;
