import { useContext, useEffect, useState } from 'react';
import { AddressState } from "../../Context/AddressContext";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import { Link, useNavigate } from 'react-router-dom';
import { addAddress, addressTypes, getStateAndCity } from '../../Networking/AddressApisActions';
import Tick from "../../assets/svg/tick.svg";

const NewAddress = () => {
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fullName, setFullName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [phone, setPhone] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [errors, setErrors] = useState("");

  const [selectedAddressType, setSelectedAddressType] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);

  const addressContext = useContext(AddressState);
  const userId = useContext(Auth);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    addressTypes().then(res => {
      addressContext.updateTypes(res.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getStateAndCity().then(res => {
      addressContext.updatePlaces(res.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const selectedState = addressContext.getPlaces.find(item => item.id === parseInt(state));
    if (selectedState) {
      setFilteredCities(selectedState.listCities);
    }
  }, [state, addressContext.getPlaces]);

  const handleRadioChange = (e) => {
    setSelectedAddressType(e.target.value);
  };

  const handleSubmit = () => {
    if (fullName.length === 0 || addressLine1.length === 0 || addressLine2.length === 0 || landmark.length === 0 || phone.length === 0 || pincode.length === 0 || selectedAddressType.length === 0) {
      return setErrors("Please Fill out these fields");
    }

    setLoading(true);
    const bodyData = {
      userId: userId.customerId,
      name: fullName,
      streetName: addressLine1,
      locality: addressLine2,
      city: city,
      state: state,
      pincode: pincode,
      landmark: landmark,
      addressType: selectedAddressType,
      phone: phone,
      default: isChecked
    }    

    addAddress(bodyData).then(res => {
      if (res.data.code === 200) {
        navigate('/Address');
        setLoading(false);
        setErrors("");
      }
      if (res.data.code === 204) {
        setErrors(res.data.message);
        setTimeout(() => setErrors(""), 3000);
        setLoading(false);
      }      
    }).catch(err => console.log(err));
  }

  return (
    <div className="max-w-7xl bg-white border border-gray-200 mt-10 border-opacity-5 shadow-md mx-auto font-Inter p-4 pb-12">
      {loading && <Loader />}
      <div className="flex">
        <Link to={"/Address"}>
          <p className="text-13px">Your Account &gt; Your Address</p>        
        </Link>
        <span className="text-yellow text-13px"> &nbsp;&gt; New Address</span>
      </div>
      <div className='flex items-center justify-between'>
        <p className="text-17px text-yellow font-semibold mt-3">Your Address</p>
        <p className='cursor-pointer text-lg' onClick={() => navigate('/Address')}>🗙</p>
      </div>
      
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 gap-x-14 gap-y-6">
        <div className="flex -mb-20 gap-x-5">
          {addressContext.addressTypes.map(item => (
            <div key={item.id}>
              <input type='radio' value={item.id} onChange={handleRadioChange} name='addressType' className='accent-yellow scale-125' />
              <label className="ms-1.5 font-Inter font-semibold uppercase text-sm">{item.name}</label>
            </div>
          ))}
          {errors && <p className='text-xs text-red font-Inter mt-1.5'>{errors}</p>}
        </div>
        <br />
        <div>
          <label className="block text-gray-500 font-semibold">Country/Region</label>
          <input type="text" value={addressContext?.getPlaces[0]?.country} disabled className="w-full border pl-5 appearance-none font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none"/>
        </div>
        <div>
          <label className="block font-Inter font-medium">Full Name (First and Last name)</label>
          <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} className="w-full border pl-5 appearance-none font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none" />
          {errors && !fullName ? <p className="text-xs text-red">{errors}</p> : ""}
        </div>
        <div>
          <label className="block font-Inter font-medium">Flat, House No.</label>
          <input type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} className="w-full border pl-5 appearance-none font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none" />
          {errors && !addressLine1 ? <p className="text-xs text-red">{errors}</p> : ""}
        </div>
        <div>
          <label className="block font-Inter font-medium">Area, Street, Sector, Village</label>
          <input type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} className="w-full border pl-5 appearance-none font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none" />
          {errors && !addressLine2 ? <p className="text-xs text-red">{errors}</p> : ""}
        </div>
        <div>
          <label className="block font-Inter font-medium">Landmark</label>
           <input type="text" value={landmark} onChange={(e) => setLandmark(e.target.value)} placeholder="Eg. Near Apollo Hospital" className="w-full border pl-5 appearance-none font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none" />
          {errors && !landmark ? <p className="text-xs text-red">{errors}</p> : ""}
        </div>
        <div>
          <label className="block font-Inter font-medium">Mobile number</label>
          <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className="w-full border pl-5 appearance-none font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none" />
          {errors && !phone ? <p className="text-xs text-red">{errors}</p> : ""}
        </div>
        <div>
          <label className="block font-Inter font-medium">Pincode</label>
          <input type="number" value={pincode} onChange={(e) => setPincode(e.target.value)} className="w-full border pl-5 appearance-none font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none" />
          {errors && !pincode ? <p className="text-xs text-red">{errors}</p> : ""}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block font-Inter font-medium">State</label>
            <select value={state} onChange={(e) => setState(e.target.value)} className="w-full border pl-5 font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none">
              <option defaultChecked hidden>Select State</option>
              {addressContext.getPlaces.map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}              
            </select>
          {errors && !state ? <p className="text-xs text-red">{errors}</p> : ""}
          </div>
          <div>
            <label className="block font-Inter font-medium">Town/City</label>
            <select value={city} onChange={(e) => setCity(e.target.value)} className="w-full border pl-5 font-normal text-15px border-yellow py-2.5 rounded-md border-opacity-50 mt-1 focus:outline-none">
              <option defaultChecked hidden>Select Town/City</option>
            {filteredCities.map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}             
            </select>
          {errors && !city ? <p className="text-xs text-red">{errors}</p> : ""}
          </div>
        </div>
      </div>

      <div className='mt-6 flex justify-between'>
        <CheckBox onClick={() => setIsChecked(!isChecked)} isChecked={isChecked}/>
        <button onClick={handleSubmit} className='bg-yellow text-white px-7 py-3 rounded-sm shadow-xl'>Add Address</button>
      </div>

    </div>
  )
}

export default NewAddress


const CheckBox = ({onClick, isChecked}) => {
  return (
    <div className="sm:col-span-2 gap-2 flex items-center">
      <div
        className={`border-2 w-5 h-5 p-0.5 cursor-pointer ${
          isChecked ? "border-gray-700" : "border-gray-300"
        }`}
        onClick={onClick}
      >
        {isChecked ? (
          <img src={Tick} className="scale-150" alt="" />
        ) : (
          <span style={{ width: 20, height: 20 }} />
        )}
      </div>
      <label className="text-13px font-medium">
        Make this my default address
      </label>
    </div>
  );
};