import React, { useState, createContext, useEffect } from 'react'
import CryptoJS from 'crypto-js';


export const Auth = createContext();

const AuthContext = (props) => {
  const [userData, setUserData] = useState(0);
  const [otp, setOtp] = useState(null);
  const [userId, setUserId] = useState(0);
  const [customerId, setCustomerId] = useState(0);

  // initial otp gett place
  const updatecheckOtp = (getOtp) => {
    setOtp(getOtp.otp);
    setUserId(getOtp.id);
    setUserData(getOtp)
  }
  const updateUserData = (userdata) => {
    setUserData(userdata)
  }
  useEffect(() => {
    const userDataId = localStorage.getItem('UserKey');
    if (userDataId && customerId === 0) {
      if (userDataId !== null && userDataId !== '') {
        const bytes = CryptoJS.AES.decrypt(userDataId, 'UserKey');
        const decryptedUserId = bytes.toString(CryptoJS.enc.Utf8);
        setCustomerId(decryptedUserId)
      }
    }
  }, [userId]);

  return <Auth.Provider value={{
    userData, updatecheckOtp, updateUserData, userId, customerId, otp, setCustomerId
  }}>
    {props.children}
  </Auth.Provider>

}



export default AuthContext