import React, { useState, useEffect, useRef } from "react";
import ProductImg from "../../assets/Rectangle 390.png";
import  OrdersTick from "../../assets/svg/tick.svg";
import OrderTrackingH from "../../acscomponents/OrderTrackingH";

const OrderDetails = () => {
  const heading = "Home > My Account > My Orders > ";
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("click", handleClickOutside, true);
    } else {
      document.removeEventListener("click", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isVisible]);

  return (
    <>
    
      <div className="px-0 md:px-40px lg:px-90px">
        {/* Heading Section */}
        <div className="flex flex-row font-Inter font-medium tracking-wide text-15px pt-3 md:pt-46px pb-3">
          <h5 className="">
            {heading}
            <span className="text-dark-brown-opacity-60">OD24595968958663</span>
          </h5>
        </div>
        {/* Address Section */}
        <div className="flex flex-col md:flex-row shadow-sm bg-white justify-between font-Inter tracking-wide pt-3">
          <div className="flex flex-col flex-1">
            <div className="ps-2 pe-2 md:ps-36px md:pe-36px">
              <h6 className="text-dark-brown text-15px font-normal">
                Delivery Address
              </h6>
              <div className="pt-2 pb-5 text-15px font-medium">
                <p>Sakthi.C</p>
              </div>
              <div className="flex flex-col flex-1 text-14px font-normal">
                <p>12/7 North Street,</p>
                <p>Vasantham Place theatre Nagercoil- 629001</p>
                <p>Tamil Nadu</p>
              </div>
              <div className="flex flex-col md:flex-row pt-4 pb-5 text-15px font-normal">
                <div className="flex-2">Phone number</div>
                <div className="flex-4">9865269538</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-2">
            <div className="ps-0 lg:ps-36">
              <div className="flex flex-row text-15px font-normal text-dark-brown">
                Your Rewards
              </div>
              <div className="flex flex-row pl-56px text-15px font-normal text-black pt-3">
                No Rewards
              </div>
            </div>
          </div>
        </div>

        {/* Product Order Section */}
        <div className="flex flex-col text-center md:flex-row lg:flex-col xl:flex-row  md:text-left justify-center items-center md:items-center md:ps-0 shadow-sm bg-white mt-5 py-2 md:py-9">
          <div className="flex flex-col flex-1 flex-shrink-0 px-28px">
            <img src={ProductImg} alt="" width={150} height={117} />
          </div>
          <div className="flex flex-col flex-1 px-5 md:relative lg:ps-4">
            <div className="flex flex-col gap-2">
              <p className="font-Inter font-medium text-15px">
                Mix Dried Berries
              </p>
              <p className="font-Inter font-medium text-15px text-light-red">
                Rs.300.00
              </p>
              <p
                className="font-quicksand font-semibold text-13px cursor-pointer hover:text-dark-brown"
                onClick={handleToggle}
              >
                Amount details
              </p>

              {/* Amount Details Section */}
              {isVisible && (
                <div
                  ref={contentRef}
                  className="flex flex-col absolute md:bottom-0 left-0 ml-4 md:ml-0 drop-shadow-2xl bg-white"
                >
                  <div className="flex flex-col w-72 md:w-80 lg:w-400px">
                    <h2 className="font-medium text-md pl-24px pt-2 pb-2 font-quicksand text-13px leading-5">
                      Price details
                    </h2>
                    <hr className="border border-black opacity-10" />
                    <div className="flex flex-row pl-24px pr-24px pt-10px font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50%">
                        <p>Price ( 1 item )</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right">Rs.300</p>
                      </div>
                    </div>
                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50%">
                        <p>Tax</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right pr-5">-</p>
                      </div>
                    </div>

                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50% ">
                        <p>Discount</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right">Rs. 50</p>
                      </div>
                    </div>
                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px pb-3 font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50% ">
                        <p>Delivery Charges</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right text-green">Free</p>
                      </div>
                    </div>
                    <hr className="border border-black opacity-10" />
                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px pb-3 font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50% font-quicksand font-medium">
                        <p>Total Amount</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right text-13px font-bold">
                          Rs. 250
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>


          <div className="flex flex-col flex-5 justify-start items-center md:items-center lg:items-start pt-5">
            <div className="flex flex-col md:flex-col lg:flex-row  font-Inter font-medium text-16px tracking-normal  text-dark-brown pt-10">

            <div className="flex flex-col flex-4 justify-start  pl-12 items-center md:items-center lg:items-start">
            <div className="flex flex-col md:flex-col lg:flex-row  font-Inter font-medium text-16px tracking-normal text-dark-brown">
              <OrderTrackingH stage={[  
                {id : 1, stage: 'Order Confirmed', date: 'Thu, 17th Aug'},
                {id : 2, stage: 'Shipped', date: 'Thu, 17th Aug'},
                {id : 3, stage: 'Out of Delivery', date: 'Sat, 19th Aug'},
                {id : 4, stage: 'Delivered', date: 'Thu, 25th Aug'}
              ]}/>
            </div>
            <div className="flex flex-row justify-center items-center font-Inter font-normal text-15px tracking-wide text-black-opacity-60 pt-5 lg:ps-16">
                <p>Your Item has been Delivered.</p>
            </div>
          </div>


              {/* <div className="flex flex-row lg:flex-col justify-center items-start lg:items-center ">     
              <div className="">
                <p>Order Confirmed</p>
                </div>           
                <div className="flex flex-col md:flex-col lg:flex-row ps-0 lg:ps-28 gap-0 lg:gap-3">
                    <img
                        className="cursor-pointer pt-2 pb-2"
                        src={OrdersTick}
                        alt=""
                        srcset=""
                        width={20}
                        height={20}
                    />
                    <div className="bg-green lg:w-28 lg:h-0.5 lg:mt-4 lg:mx-0 w-0.5 h-24 my-0 mx-2 md:bg-green"></div>
                </div>
                <p className="font-Inter font-normal text-15px text-black-opacity-60">
                  Thu,17th Aug
                </p>
              </div> */}
              {/* <div className="flex flex-row lg:flex-col justify-center items-start ps-66px md:ps-66px lg:ps-0">     
              <div className="mt-5 lg:mt-0">
                <p>Shipped</p>
                </div>           
                <div className="flex flex-col md:flex-col lg:flex-row ps-0 lg:ps-0 gap-0 lg:gap-2">
                    <div className="bg-green lg:w-3 lg:h-0.5 lg:mt-16px lg:mx-0 w-2.4px h-4 my-0 mx-7.5px md:bg-green"></div>
                    <img
                        className="cursor-pointer pt-2 pb-2"
                        src={OrdersTick}
                        alt=""
                        srcset=""
                        width={20}
                        height={20}
                    />
                    <div className="bg-green lg:w-28 lg:h-0.5 lg:mt-4 lg:mx-0 w-0.5 h-24 my-0 mx-2 md:bg-green"></div>
                </div>
                    
                <p className="font-Inter font-normal text-15px text-black-opacity-60 mt-5 lg:mt-0">
                  Thu,17th Aug
                </p>
              </div> */}
              {/* <div className="flex flex-row lg:flex-col justify-center items-start ps-3 md:ps-3 lg:ps-0 ">     
              <div className="mt-5 lg:mt-0">
                <p>Out of Delivery</p>
                </div>           
                <div className="flex flex-col md:flex-col lg:flex-row ps-0 lg:ps-0 gap-0 lg:gap-2">
                    <div className="bg-green lg:w-3 lg:h-0.5 lg:mt-4 lg:mx-0 w-2.4px h-4 my-0 mx-7.5px md:bg-green"></div>
                    <img
                        className="cursor-pointer pt-2 pb-2"
                        src={OrdersTick}
                        alt=""
                        srcset=""
                        width={20}
                        height={20}
                    />
                    <div className="bg-green lg:w-28 lg:h-0.5 lg:mt-4 lg:mx-0 w-0.5 h-24 my-0 mx-2 md:bg-green"></div>
                </div>
                <p className="font-Inter font-normal text-15px text-black-opacity-60 mt-5 lg:mt-0">
                    Sat,19th Aug
                </p>
              </div> */}
              {/* <div className="flex flex-row lg:flex-col justify-center items-start ps-16 md:ps-18 lg:ps-0">     
              <div className="mt-5 lg:mt-0">
                <p>Delivered</p>
                </div>           
                <div className="flex flex-col md:flex-col lg:flex-row ps-0 lg:ps-0 gap-0 lg:gap-2">
                    <div className="bg-green lg:w-3 lg:h-0.5 lg:mt-4 lg:mx-0 w-0.5 h-4 my-0 mx-2 md:bg-green"></div>
                    <img
                        className="cursor-pointer pt-2 pb-2"
                        src={OrdersTick}
                        alt=""
                        srcset=""
                        width={20}
                        height={20}
                    />
                </div>
                <p className="font-Inter text-15px font-bold text-black mt-5 lg:mt-0">
                    Oct,25th Aug
                </p>
              </div> */}
            </div>
            {/* <div className="flex flex-row justify-center items-center font-Inter font-normal text-15px tracking-wide text-black-opacity-60 pt-5 lg:ps-16">
                    <p>Your Item has been Delivered.</p>
                </div> */}
          </div>


          {/* <div className="flex flex-col flex-3 justify-start items-center md:items-center lg:items-start pt-5">
            <div className="flex flex-col md:flex-col lg:flex-row">
              <div className="flex flex-col md:flex-col lg:flex-row md:gap-4 lg:gap-20 pb-2 font-Inter font-medium text-16px tracking-normal text-dark-brown">
                <p>Order Confirmed</p>
                <p>Shipped</p>
                <p>Out of Delivery</p>
                <p>Delivered</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-col lg:flex-row lg:ps-12 ps-0">
              <img
                className="cursor-pointer"
                src={OrdersTick}
                alt=""
                srcset=""
                width={20}
                height={20}
              />
              <div className="bg-green lg:w-32 lg:h-0.5 lg:mt-2 lg:mx-3 w-0.5 h-24 my-1 mx-2 md:bg-green"></div>
              <img
                className="cursor-pointer"
                src={OrdersTick}
                alt=""
                srcset=""
                width={20}
                height={20}
              />
              <div className="bg-green lg:w-32 lg:h-0.5 lg:mt-2 lg:mx-3 w-0.5 h-24 my-1 mx-2 md:bg-green"></div>
              <img
                className="cursor-pointer"
                src={OrdersTick}
                alt=""
                srcset=""
                width={20}
                height={20}
              />
              <div className="bg-green lg:w-32 lg:h-0.5 lg:mt-2 lg:mx-3 w-0.5 h-24 my-1 mx-2 md:bg-green"></div>
              <img
                className="cursor-pointer"
                src={OrdersTick}
                alt=""
                srcset=""
                width={20}
                height={20}
              />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-row">
              <div className="flex flex-col md:flex-col lg:flex-row md:gap-4 lg:gap-20 pb-2 pt-2">
                <p className="font-Inter font-normal text-15px text-black-opacity-60">
                  Thu,17th Aug
                </p>
                <p className="font-Inter font-normal text-15px text-black-opacity-60">
                  Thu,17th Aug
                </p>
                <p className="font-Inter font-normal text-15px text-black-opacity-60">
                  Sat,19th Aug
                </p>
                <p className="font-Inter text-15px font-bold">Oct,25th Aug</p>
              </div>
            </div>
            <div className="font-Inter font-normal text-15px tracking-wide text-black-opacity-60 pt-5">
              <p>Your Item has been Delivered.</p>
            </div>
          </div> */}


        </div>
        <div></div>
      </div>
    </>
  );
};

export default OrderDetails;
