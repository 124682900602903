import React from 'react';

const RatingComponent = ({ RatingreviewData }) => {

    const widthPercentage = (reviews, totalReviewer) => {
        return totalReviewer ? `${(reviews / totalReviewer) * 100}%` : '0%';
    };

    const progressBarColor = (reviews) => {
        if (reviews > 5000) {
            return '#4CAF50';
        } else if (reviews > 2000) {
            return '#FFA900';
        } else if (reviews > 1000) {
            return '#CCA144';
        } else if (reviews <= 0) {
            return '#D9D9D9';
        } else {
            return '#FF0000';
        }
    };


    const validData = RatingreviewData && RatingreviewData[0] && Array.isArray(RatingreviewData[0].ratings);
    const ratings = validData ? RatingreviewData[0].ratings : [{ value: "5", reviews: 0 }, { value: "4", reviews: 0 }, { value: "3", reviews: 0 }, { value: "2", reviews: 0 }, { value: "1", reviews: 0 }];
    const totalReviewer = validData ? RatingreviewData[0].totalReviewer : 0;

    return (
        <div style={{ padding: '0px 20px' }}>
            <div>
                <h3 style={{ color: '#703F07', fontFamily: 'jaldi', fontSize: 24, fontWeight: 'bolder' }}>Ratings & Reviews</h3>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginTop: 25 }}>
                <div style={{ display: 'block' }}>
                    <p style={{ fontSize: 35, fontWeight: 'bold', display: 'flex', justifyContent: 'space-around', lineHeight: 0.5 }}>4.3 <span>&#9733;</span> </p>
                </div>
                <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column', lineHeight: 0.8, textAlign: 'right', width: '100%' }}>
                    {ratings.map((item, index) => (
                        <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <p style={{ margin: '0px 0px', fontSize: '18px', fontWeight: 'bold', letterSpacing: '2px',textWrap:'nowrap'  }}>{item.value} &#9733;</p>
                            <div style={{ width: '100%', height: 14, backgroundColor: '#D4D4D5', borderRadius: 10, overflow: 'hidden' }}>
                                <div style={{ width: widthPercentage(item.reviews, totalReviewer), height: '100%', backgroundColor: progressBarColor(item.reviews), borderRadius: 10 }}></div>
                            </div>
                            <div style={{ margin: '1px 5px', fontFamily: 'quicksand', fontSize: '15px', fontWeight: 600, color: '#000000b1' }}>
                                <span>{item.reviews}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RatingComponent;