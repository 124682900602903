import React, { useContext, useEffect, useState } from "react";
import {
  Greenstar,
  Blackdownarrow,
  Blackuparrow,
  heartblack,
  heartbrown,
} from "../../assets/svg/index";
import { ProductContextStates } from "../../Context/ProductContext";
import { getAllProducts, getProductDetails } from "../../Networking/ProductApisAction";
import { addFavourited, deleteFavourited } from "../../Networking/FavouriteApisAction";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../acscomponents/Loader";
import { Auth } from "../../Context/AuthContext";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const productContextStates = useContext(ProductContextStates);
  const AuthUserContext = useContext(Auth);
  const [categories, setCategories] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [displayStatus, setDisplayStatus] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState({});
  const [filteredCategory, setFilteredCategory] = useState('');
  const [brandFilerData, setBrandFilerData] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const { selectedCategoryId } = location.state;

  useEffect(() => {
    if (selectedCategoryId) {
      setCategoryId(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  const toggleDisplayStatus = () => {
    setDisplayStatus(!displayStatus);
  };

  const handleProductDetails = (selectedProductId) => {
    navigate('/ProductDetails', { state: { selectedProductId: selectedProductId, categoryId: categoryId } });
  };

  useEffect(() => {
    if (categoryId) {
      setLoading(true);

      const bodyData = {
        userId: AuthUserContext.customerId ? AuthUserContext.customerId : 0,
        categoryId: categoryId,
        subCategoryId: 0,
        productCategory: 0,
        brandId: 0
      };

      getAllProducts(bodyData).then((response) => {
        const data = response?.data?.data || {};
        const code = response?.data?.code || 0;
        if (data && code === 200) {
          setLoading(false);
          productContextStates.getAllProductCategories(data);
          const filteredProducts = data.listAllProductItems.filter((product) => {
            const matchesSubCategory = filteredCategory ? product.subCategoryId === filteredCategory : true;
            const matchesBrand = brandFilerData ? product.brandId === brandFilerData : true;
            return matchesSubCategory && matchesBrand;
          });
          setProducts(filteredProducts.length > 0 ? filteredProducts : data.listAllProductItems || []);
          setCategories(data.listSubCategories || []);
          setBrandData(data.listBrands || []);
        } else {
          setProducts([]);
          setLoading(false);
        }
      }).catch(error => {
        console.error("Error fetching products:", error);
        setLoading(false);
      });
    }
  }, [categoryId, filteredCategory, brandFilerData]);

  useEffect(() => {
    if (products.length > 0) {
      products.forEach((product) => {
        handleFavourite(product.id);
      });
    }
  }, [products]);

  const handleFavourite = (id) => {
    const bodyData = {
      requestId: id,
      userId: AuthUserContext.customerId,
    };
    getProductDetails(bodyData).then((response) => {
      const data = response?.data?.data || {};
      const isFavourited = data.favourite;
      setIsFavourite((prevStatus) => ({
        ...prevStatus,
        [id]: isFavourited,
      }));
    }).catch(error => {
      console.error("Error checking favourite:", error);
    });
  };

  const handleCheckOut = (id) => {
    setLoading(true);
    const selectedProduct = products.find((product) => product.id === id);
    setIsFavourite(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));

    if (isFavourite[id]) {
      const bodyData = {
        requestId: id,
        userId: AuthUserContext.customerId,
      };
      deleteFavourited(bodyData).then(response => {
        const data = response.data.data;
      }).catch(error => {
        console.error("Error deleting favourite:", error);
      });
    } else {
      const bodyData = {
        userId: AuthUserContext.customerId,
        productId: selectedProduct.id,
        category: selectedProduct.productCategoryId,
        subCategory: selectedProduct.subCategoryId,
        productCategory: selectedProduct.productCategoryId,
        brand: selectedProduct.brandId,
        color: selectedProduct.colorId,
        unit: selectedProduct.unitId,
        productSize: selectedProduct.productSizeId
      };
      addFavourited(bodyData).then(response => {
        const data = response.data.data;
      }).catch(error => {
        console.error("Error adding favourite:", error);
      });
    }

    setLoading(false);
  };

  const breadCrumb = "Home > Food Products > Dry Fruit Nuts... > Dry Fruits";
  //   Showing 1-20 of 20 results for "almonds" Show results for badam  instead
  const showingResults = `Showing 1-${products.length} results for ${products.length} ${products[0]?.categoryName || ''} show results for ${products[0]?.subCatName || ''} instead.`;


  return (
    <>
      {loading && (
        <Loader className={loading ? "blur-md z-30" : "blur-none"} />)}
      <div className="h-screen no-scroll overflow-y-auto">

        {/* {products.length > 0 ? ( */}
        <div className="grid grid-flow-row md:flex bg-lightgrey font-jaldi w-full md:px-2 ">
          {/* sidebar */}
          <div className="bg-white md:m-3 m-1 md:w-40% lg:w-25% h-screen overflow-auto  sticky ">
            <h3 className="bg-white border-b border-gray-300 font-bold text-24px text-dark-brown px-6 py-3">
              Filters
            </h3>
            {/* dummy static category */}
            <div className="px-6 py-2 border-b border-gray-300 ">
              <h3 className="mb-5px text-16px opacity-80% cursor-pointer">
                CATEGORIES
              </h3>
              <ul
                className={
                  "list-none flex md:flex-col gap-3px overflow-hidden mt-3"
                }
              >
                <li
                  className="grid flex-wrap items-center gap-1 text-15px cursor-pointer"

                >
                  {categories.map((itm) => (
                    <p key={itm.id} className="flex flex-wrap opacity-60% text-start">
                      <span className="pl-1.5" onClick={() => setFilteredCategory(itm.id)}>
                        {/* {index < categories.length  && " >"} */}
                        {itm.subCategoryName}

                      </span>
                    </p>
                  ))}

                </li>
                {/* <li className="font-bold opacity-60% ml-14.5px cursor-pointer text-left">
                    Dry Fruits
                  </li> */}
              </ul>
            </div>

            {brandData && (
              <div className="px-6 py-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <h3 className="mb-1 font-bold text-[16px] opacity-65%">BRANDS</h3>
                  <div>
                    <img
                      src={displayStatus ? Blackuparrow : Blackdownarrow}
                      onClick={toggleDisplayStatus}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
                {displayStatus && (
                  <ul className="list-none flex flex-col gap-[3px] overflow-hidden h-auto">
                    {brandData.map((brand) => (
                      <li key={brand.id} className="flex items-center gap-2 text-[15px]">
                        <input
                          type="checkbox"
                          name={brand.id}
                          className="mt-0.5"
                          onChange={() => setBrandFilerData(brand.id)}
                        />

                        <label htmlFor={brand.brands}>{brand.brands}</label>
                      </li>
                    ))}
                  </ul>
                )}

              </div>
            )}

            {/* {filters.map((filterItm) => (
            <div
              key={filterItm.id}
              className="px-6 py-2 border-b border-gray-300"
            >

              <div className="flex justify-between">
                <h3 className="mb-5px font-bold text-16px opacity-65%">
                  {filterItm.name}
                </h3>
                <div>
                  {filterItm.displayStatus ? (
                    <img
                      src={Blackdownarrow}
                      alt=""
                      onClick={() => {
                        handleFilterItmDisplayStatus(filterItm.id);
                      }}
                      className="cursor-pointer"
                    />
                  ) : (
                    <img
                      src={Blackuparrow}
                      alt=""
                      onClick={() => {
                        handleFilterItmDisplayStatus(filterItm.id);
                      }}
                      className="cursor-pointer"
                    />
                  )}
                </div>
              </div>
              <ul
                className={`list-none flex flex-col gap-3px overflow-hidden ${filterItm.displayStatus ? "h-auto" : "h-0"
                  }`}
              >
                {filterItm.contents.map((content) => (
                  <li
                    key={content}
                    className="flex items-center gap-2 text-15px"
                  >
                    <input
                      type="checkbox"
                      name={content}
                      className="mt-0.5px"
                    />
                    <label htmlFor={content} className="">
                      {content}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))} */}

            <ul
              className={`list-none flex flex-col gap-3px overflow-hidden ${displayStatus ? "h-auto" : "h-0"
                }`}
            >
              {/* {contents.map((content) => (
                <li
                  key={content}
                  className="flex items-center gap-2 text-15px"
                >
                  <input
                    type="checkbox"
                    name={content}
                    className="mt-0.5px"
                  />
                  <label htmlFor={content} className="">
                    {content}
                  </label>
                </li>
              ))} */}
            </ul>

          </div>
          {/* right side */}
          {products && products.length > 0 ? (
            <div className="lg:w-75% bg-lightgrey pr-1 md:pr-0 h-screen overflow-auto no-scroll ">
              <div className="sticky z-10 top-0 bg-lightgrey pt-3 pb-2 w-full pl-2">
                <p className="text-14px opacity-60%" >{breadCrumb}
                </p>
                <p className="text-14px mt-3">


                  {showingResults}
                </p>
              </div>
              <div className="flex flex-wrap mt-4">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className="flex flex-flex-1-100% md:flex-flex-1-50% lg:flex-flex-1-30% md:mt-1 md:p-2 mt-1 p-1 cursor-pointer"

                  >
                    <div className="px-4 py-2.5 bg-white relative flex-1 transition-shadow duration-500 ease-in-out hover:shadow-2xl " >
                      <div className="flex justify-center h-32 w-full p-1 ">

                        <img className="p-1.5 shadow-inner inset-3 object-contain" src={product.mainImage} />
                        {
                          <img
                            src={isFavourite[product.id] ? heartbrown : heartblack}
                            className={`absolute right-5 cursor-pointer ${product.inCart ? "opacity-0" : isFavourite[product.id] ? "opacity-100" : "opacity-40"}`}
                            onClick={() => handleCheckOut(product.id)}
                          />
                        }
                      </div>
                      <div className="flex flex-col px-1.5 " onClick={() => handleProductDetails(product.id)}>
                        <p className="opacity-60% text-14px ">
                          Sponsored
                        </p>
                        <p className="text-14px">{product.product}</p>
                        <div className="flex justify-between items-center ">
                          <p className="text-black font-bold">{product.productSize}</p>
                          <div className="text-emerald-green bg-lightgreen rounded-5px flex justify-center items-center h-5 w-10 gap-1 ">
                            <img src={Greenstar} alt="" />
                            <span className="text-13px font-quicksand font-bold">
                              {product.rating}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="font-bold text-red text-25px p-1.5">
                        Rs. {product.discountedPrice}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="sticky top-10 pt-16 font-bold tracking-widest text-dark-brown flex justify-center">No data Found</p>
          )}
        </div >
      </div >
    </>
  );
};
export default ProductList;
