import React, { useState, createContext } from "react";


const ProductContextStates = createContext();

const ProductContext = (props) => {
    const [getCategories, setCategories] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedId, setSelectedIdData] = useState(0);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [selectedProductDetails, setselectedProductDetails] = useState(0);
    const [cartList, setCartList] = useState([])
    const [filterProducts, setFilteredProucts] = useState([]);
    const [orderSummaryData, setOrderSummaryData] = useState([]);

    const getAllCategoriesData = (categoriesData) => {
        setCategories(categoriesData);
    }

    const categorySelectedIdData = (selectId) => {
        setSelectedIdData(selectId)
    }

    const getAllProductCategories = (selectedData) => {
        setSelectedSubCategories(selectedData)
    }
    const selectProductDataId = (id) => {
        setselectedProductDetails(id);
    }

    const updateCartList = (updatedCart) => {
        setCartList(updatedCart)
    }

    // FAVOURITE CONTEXT

    const getfilteredProducts = (filteredData) => {
        setFilteredProucts(filteredData)
    }

    // order summary

    const OrderSummary = (orderData) => {
        setOrderSummaryData(orderData)
    }


    return (
        <ProductContextStates.Provider value={{ getAllCategoriesData, getCategories, selectedId, categorySelectedIdData, getAllProductCategories, selectedSubCategories, selectProductDataId, selectedProductDetails, updateCartList, cartList, getfilteredProducts, filterProducts, orderSummaryData, OrderSummary }}>
            {props.children}
        </ProductContextStates.Provider>
    )

}

export { ProductContextStates, ProductContext }
