import React, { useContext, useEffect, useState } from 'react'
import Loader from '../../acscomponents/Loader'
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from '../../Context/AuthContext'
import { ProductContextStates } from '../../Context/ProductContext';
import { getAllProducts } from '../../Networking/ProductApisAction';
import PriceSeeker from '../../acscomponents/PriceSeeker';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const productContextStates = useContext(ProductContextStates);
  const AuthUserContext = useContext(Auth);
  const [categories, setCategories] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [displayStatus, setDisplayStatus] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState({});
  const [filteredCategory, setFilteredCategory] = useState('');
  const [brandFilerData, setBrandFilerData] = useState(0);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const selectedCategoryId = 3;
  useEffect(() => {
    if (selectedCategoryId) {
      setCategoryId(selectedCategoryId);
    }
  }, [selectedCategoryId]);
  useEffect(() => {
    if (categoryId) {
      setLoading(true);

      const bodyData = {
        userId: AuthUserContext.customerId ? AuthUserContext.customerId : 0,
        categoryId: categoryId,
        subCategoryId: 0,
        productCategory: 0,
        brandId: 0
      };

      getAllProducts(bodyData).then((response) => {
        const data = response?.data?.data || {};
        const code = response?.data?.code || 0;

        if (data && code === 200) {
          setLoading(false);
          setMinimumPrice(data.minPrice);
          setMaximumPrice(data.maxPrice);
          productContextStates.getAllProductCategories(data);
          const filteredProducts = data.listAllProductItems.filter((product) => {
            const matchesSubCategory = filteredCategory ? product.subCategoryId === filteredCategory : true;
            const matchesBrand = brandFilerData ? product.brandId === brandFilerData : true;
            return matchesSubCategory && matchesBrand;

          });
          setProducts(filteredProducts.length > 0 ? filteredProducts : data.listAllProductItems || []);
          setCategories(data.listSubCategories || []);
          setBrandData(data.listBrands || []);

        } else {
          setProducts([]);
          setLoading(false);
        }
      }).catch(error => {
        console.error("Error fetching products:", error);
        setLoading(false);
      });
    }
  }, [categoryId, filteredCategory, brandFilerData]);

  console.log(minimumPrice, maximumPrice, 'min max');

  const showingResults = `Showing 1-${products.length} results for ${products.length} ${products[0]?.categoryName || ''} show results for ${products[0]?.subCatName || ''} instead.`;


  return (
    <>
      {loading && (
        <Loader classname={loading ? "blur-sm z-50" : "blur-none"} />
      )}
      <div className="grid grid-rows-2 md:grid-cols-11 bg-white p-2.5 mt-2 gap-1">
        {/* Left Part* */}
        <div className="row-span-1 md:col-span-2 border-2 h-full">
          <h3 className="border-gray-300 border-b-2 px-2.5 py-1.5 font-bold text-20px text-dark-brown ">
            Filters
          </h3>
          {/*  categories part * */}
          <div className="px-6 py-2 border-b border-gray-300 ">
            <h3 className="mb-5px text-12px opacity-80% cursor-pointer font-semibold">
              CATEGORIES
            </h3>
            <ul
              className={
                "list-none flex md:flex-col gap-3px overflow-hidden md:pt-1.5"
              }
            >
              <li
                className="flex md:grid md:flex-wrap items-center gap-1 text-13px cursor-pointer"
              >
                {categories.map((itm) => (
                  <p key={itm.id} className="text-nowrap md:text-wrap opacity-60% text-start">
                    <span className="pl-1.5" onClick={() => setFilteredCategory(itm.id)}>
                      {itm.subCategoryName}
                    </span>
                  </p>
                ))}
              </li>
            </ul>
          </div>
          {/*Price part */}
          <div className="">
            <PriceSeeker min={minimumPrice} max={maximumPrice} />
          </div>
        </div>
        {/* right part* */}
        <div className="row-span-1 md:col-span-9 border-2">
          hey
        </div>
      </div>
    </>
  )
}

export default ProductList