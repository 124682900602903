import React from "react";
import { gift, lock, location, audio } from "../../assets/svg/index";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";

function MyProfile() {
  const navigate = useNavigate();

  const profile = [
    {
      icon: gift,
      title: "Your Orders",
      description: "Track, return, or buy things again.",
      route: "/OrderPage", 
    },
    {
      icon: lock,
      title: "Login & security",
      description: "Edit login, name, and mobile number.",
      route: "/login-security", 
    },
    {
      icon: location,
      title: "Your Addresses",
      description: "Edit addresses for orders and gifts.",
      route: "/Address", 
    },
    {
      icon: audio,
      title: "Contact Us",
      description: "Edit addresses for orders and gifts.",
      route: "/contact-us", 
    },
  ];

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div className="bg-white h-screen border-t-2 ">
      <p className="font-semibold font-Quicksand text-brown flex justify-start ml-5 pt-3  text-18px lg:px-36 px-5">
        Your Account
      </p>

      <div className="grid gap-4 mx-5 lg:mx-32 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {profile.map((option, index) => (
          <div key={index} className="p-5 cursor-pointer" onClick={() => handleNavigation(option.route)}>
            <div className="hover:translate-y-0.5 transition-transform duration-300 h-full flex items-stretch">
              <div className="border border-yellow-700 w-full rounded-lg bg-white hover:bg-gray-200 transition duration-500 flex items-center p-5">
                <div className="flex-shrink-0 mr-4">
                  <img src={option.icon} alt="No Image found" />
                </div>
                <div className="flex-1">
                  <p className="font-Quicksand text-20px font-semibold">
                    {option.title}
                  </p>
                  <p className="font-Quicksand text-15px opacity-60">
                    {option.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyProfile;
