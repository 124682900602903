import React, { useContext, useEffect, useState } from "react";
import LoginImage from "../../assets/Login2.png";
import { Link, useNavigate } from "react-router-dom";
import { Phone } from "../../assets/svg/index";
import {
  checkPhone,
  verifyPhone,
  resendOtpDetails
} from "../../Networking/AuthApisActions";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import CryptoJS from "crypto-js";

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isTimersent, setIsTimerSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(3);
  const navigate = useNavigate();

  const authUserContext = useContext(Auth);

  const handleOtp = (event) => {
    event.preventDefault();
    setError("");  // Clear any previous error
    if (phoneNumber.length !== 10) {
      setError("Phone number must be exactly 10 digits");
      return;
    }
    setLoading(true);
    setTimeLeft(3);
    setIsTimerSent(true);
    const bodyData = {
      phone: phoneNumber,
      otp: ''
    };
    checkPhone(bodyData).then(response => {
      const data = response.data;
      if (data.code === 200) {
        setIsOtpSent(true);
        authUserContext.updatecheckOtp(data.data);
      } else {
        setError(data.message);
      }
      setLoading(false);
    });
  };
  console.log(authUserContext.otp);

  const resendOtp = (event) => {
    setError("");
    event.preventDefault();
    setLoading(true);
    setTimeLeft(3);
    setIsTimerSent(true);
    resendOtpDetails(phoneNumber).then(response => {
      const data = response.data;
      if (data.code === 200) {
        setIsOtpSent(true);
      } else {
        setError(data.message);
      }
      setLoading(false);
    })
  }

  const verifyPhoneApi = () => {
    const bodyData = {
      id: authUserContext.userId,
      otp: authUserContext.otp,
    };
    setLoading(true);
    verifyPhone(bodyData).then(response => {
      const data = response.data;
      authUserContext.updateUserData(data.data)
      if (data.data.registered && data.data.name && data.data.email) {
        const userId = data.data.id;
        if (userId) {
          // localStorage.setItem('userId', userId);
          const encryptedUserId = CryptoJS.AES.encrypt(userId.toString(), 'UserKey').toString();
          localStorage.setItem('UserKey', encryptedUserId);
          navigate("/");
          authUserContext.setCustomerId(userId);
          
        }
      } else {
        navigate('/register', { state: { phoneNumber: phoneNumber, userId: data.data.id } });

      }
      setLoading(false);
    });
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    if (otp.length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }
    verifyPhoneApi();
  };

  useEffect(() => {
    let timer;
    if (isTimersent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    }
    if (timeLeft <= 0) {
      setIsTimerSent(false);
    }
    return () => clearInterval(timer);
  }, [isTimersent, timeLeft]);



  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <div className="flex flex-col md:flex-row h-screen border max-md:justify-center bg-white">
        <div className="flex items-center justify-center w-full md:w-1/2 bg-white shadow-md p-5">
          <div className="w-full max-w-md px-5 py-10 rounded-xl border border-light-brown shadow">
            <p className="text-center font-jaldi text-lg font-normal capitalize">
              Please{" "}
              <span className="text-dark-brown font-semibold tracking-wider">
                login/Signup
              </span>{" "}
              with your account details
            </p>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="mt-5 relative">
                <label htmlFor="phoneNumber" className="block text-lg font-jaldi">
                  Phone Number
                </label>
                <div className="relative">
                  <input
                    type="number"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setError("");
                    }}
                    className="w-full pl-3 pr-10 py-2 border border-light-brown opacity-90 rounded-md focus:outline-none focus:ring-brown-500 focus:border-brown-500"
                    required
                  />
                  <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <img
                      src={Phone}
                      alt="Phone icon"
                      className="h-5 w-5 text-gray-400"
                    />
                  </div>
                </div>
              </div>
              {error && <p className="text-sm text-red">{error}</p>}
              {isOtpSent && (
                <div className="space-y-2">
                  <label htmlFor="otp" className="block text-lg font-jaldi">
                    OTP
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="w-full pr-10 pl-3 py-2 border border-light-brown opacity-90 rounded-md shadow-sm focus:outline-none focus:ring-brown-500 focus:border-brown-500"
                    />
                  </div>
                </div>
              )}
              <div className="text-right grid grid-cols-2">
                <div className="col-span-1 flex gap-3">
                  <p className={`${isOtpSent ? "block" : "hidden"} ${timeLeft === 0 ? "opacity-0" : "opacity-100 rounded-full border text-sm p-1"}`}>
                    {timeLeft}
                  </p>
                  <button
                    className={`capitalize text-light-brown ${timeLeft === 0 ? "opacity-100" : "opacity-0 pointer-events-none"} transition-opacity duration-500`}
                    onClick={timeLeft === 0 ? resendOtp : null}
                  >
                    Resend OTP
                  </button>
                </div>
                <div className="col-span-1">
                  <Link to="/forgotPassword" className="text-sm font-medium font-Inter text-dark-brown">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {!isOtpSent ? (
                  <button
                    type="button"
                    className="px-10 py-2 text-white bg-dark-brown font-jaldi text-lg mb-5 rounded-3xl"
                    onClick={handleOtp}
                  >
                    Get OTP
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={`px-10 py-2 text-white bg-dark-brown font-jaldi text-lg mb-5 rounded-3xl ${otp && otp.length !== 6 ? "opacity-50 cursor-not-allowed" : ""}`}
                  >
                    Sign In
                  </button>
                )}
              </div>
            </form>
            <div className="text-center mt-5 hidden">
              <p className="text-lg font-inter text-gray-600">
                Don’t have an account?{" "}
                <Link to="/register" className="font-bold text-dark-brown">
                  Sign up
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="hidden md:flex w-full md:w-1/2 bg-light-brown items-center justify-center p-5">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-white font-jaldi mb-10">
              Login/SignUp to your account
            </h2>
            <img src={LoginImage} alt="Thumbnail" className="max-w-full h-auto" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
