import React, { useContext, useEffect, useState } from "react";
import {
  profilegrey,
  cartgrey,
  becomesellorgrey,
  dropdownarrow
} from "../assets/svg/index";
import ChildSearchBox from "../acscomponents/ChildSearchBox";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "../Context/AuthContext";
import { getUserDetails } from "../Networking/AuthApisActions";
import { getAllItem } from "../Networking/CartApiAction";
import Loader from "../acscomponents/Loader";
import { searchProducts } from "../Networking/ProductApisAction";
import { SearchState } from "../Context/SearchContext";
import CryptoJS from "crypto-js";

const Navbar = (props) => {
  const [search, setSearch] = useState("");
  const [dropDown, setDropdown] = useState(false);
  const [dropdownAccount] = useState([
    { id: 1, content: "My profile", imgurl: profilegrey, link: '/MyProfiler' },
    { id: 2, content: "Orders", imgurl: profilegrey, link: '/OrderPage' },
    { id: 3, content: "Wishlist", imgurl: becomesellorgrey, link: '/wishlist' },
    { id: 4, content: "Coupons", imgurl: cartgrey, link: '/Coupon' },
    { id: 5, content: "Notification", imgurl: becomesellorgrey, link: '/Notification' },
    { id: 6, content: "logout", imgurl: cartgrey, link: '/logout' },
  ]);
  const [selectedDropDown, setSelectedDropDown] = useState('');
  const [customerDetails, setCustomerDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartlength, setCartlength] = useState(0);
  const AuthUserContextStates = useContext(Auth);
  const navigate = useNavigate();

  const CustomerIDData = AuthUserContextStates.customerId;
  const { setSearchData, setMessage } = useContext(SearchState);

  const cartItems = () => {
    if (CustomerIDData) {
      const bodyDataCustomer = { requestId: CustomerIDData };
      getAllItem(bodyDataCustomer).then(response => {
        setLoading(true);
        const cartData = response.data.data;
        if (cartData) {
          setCartlength(cartData.length);
          setLoading(false);
        } else {
          setCartlength(0);
          setLoading(false);
        }
      });
    }
  };

  const UserDetailsData = () => {
    if (CustomerIDData !== 0) {
      setLoading(true);
      const bodyDataCustomer = { requestId: CustomerIDData };
      getUserDetails(bodyDataCustomer).then(response => {
        const data = response.data.data;
        const code = response.data.code;
        if (code === 200) {
          setCustomerDetails(data);
          setLoading(false);
        } else {
          setCustomerDetails([]);
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    UserDetailsData();
    cartItems();
  }, [AuthUserContextStates.customerId]);

  const deBounce = useDebounce(search);

  useEffect(() => {
    setLoading(true);
    if (deBounce) {
      searchProducts(deBounce).then(res => {
        if (res.data.code === 200) {
          setSearchData(res.data.data);
          setMessage('');
          setLoading(false);
        } else if (res.data.code === 204) {
          setSearchData([]);
          setMessage(res.data.message);
          setLoading(false);
        }
      });
    } else {
      setSearchData([]);
      setMessage('');
      setLoading(false);
    }
  }, [deBounce]);

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <div className="bg-white w-full h-full -mt-4 md:mt-0 px-5">
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <div className="flex flex-col md:flex-row items-center justify-between px-3 py-2  space-y-2 md:space-y-2 w-full">
        {/* Left Section: Logo and Search */}
        <div className="flex items-center justify-start w-full md:flex-1  space-x-4 ">
          <Link to={'/'}>
            <h3 className=" text-md md:text-23px font-bold text-dark-brown ">
              Bismi
            </h3>
          </Link>
          <div className="w-full md:w-3/5 ">
            <ChildSearchBox
              width="100%"
              height="33px"

              placeholder="Search for products, brand, offers and more"
              onInputChange={handleSearch}
            />
          </div>
        </div>

        {/* Right Section: User Account, Cart, Login */}
        <div className="flex items-center justify-end w-full md:w-auto space-x-4 gap-5">
          {CustomerIDData !== 0 && (
            <div className="relative flex items-center space-x-4 ">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => setDropdown(!dropDown)}
              >
                <p className="text-16px sm:text-18px text-black-opacity-60 flex items-center">
                  {customerDetails.name || "Accounts"}
                  <img src={dropdownarrow} alt="dropdown arrow" />
                </p>
              </div>
              {dropDown && (
                <div className="absolute top-full right-0 w-[150px] bg-gray-100 p-2 rounded shadow-md z-50">
                  {dropdownAccount.map((itm, index) => (
                    <Link to={itm.link} key={index}>
                      <div
                        className="flex items-center gap-x-2 p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {
                          setSelectedDropDown(itm.content);
                          setDropdown(false);
                        }}
                      >
                        <img src={itm.imgurl} alt={itm.content} className="w-4 h-4" />
                        <p>{itm.content}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* Cart */}
          <Link to={'/Cartpage'}>
            <div className="flex items-center cursor-pointer">
              <img src={cartgrey} alt="cart" className="h-4 sm:h-5" />
              <p className="text-16px sm:text-18px text-black-opacity-60">
                Cart <sup className="text-modelred">{cartlength > 0 && cartlength}</sup>
              </p>
            </div>
          </Link>

          {/* Login */}
          {CustomerIDData === 0 && (
            <Link to="/login">
              <button className="bg-dark-brown text-white text-13px sm:text-15px font-bold px-4 py-2 rounded-md">
                Login
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const useDebounce = (value, delay = 500) => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebounceValue(value), delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debounceValue;
};
