import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import Modal from "./Modal";
import AddressBox from "./AddressBox"; 
import { defaultAddressApi, getAddress } from "../../Networking/AddressApisActions";
import { AddressState } from "../../Context/AddressContext";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";

const Address = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const addressContext = useContext(AddressState);
  const authContext = useContext(Auth);

  const setAsDefault = (addressId) => {
    setIsLoading(true);
    const data = {
      userId: authContext.customerId,
      addressId: addressId
    }
    defaultAddressApi(data).then(res => {
      if (res.data.code === 200) {
        addressContext.updateAddress(prev => prev.map(address => address.id  === addressId ? { ...address, default: true } : { ...address, default: false }));
        setIsLoading(false);
      }
      else if (res.data.code === 204) {
        setError(res.data.message);
        setIsLoading(false);
      }
    })
  };

  useEffect(() => {
    if (authContext.customerId) {
      setIsLoading(true);
      getAddress(authContext.customerId, authContext.customerId).then(res => {
        addressContext.updateAddress(res.data.data);
        setIsLoading(false)        
      })
    }
  }, [authContext.customerId]);

  const confirmRemoveAddress = (address) => {
    setSelectedAddress(address);
    setIsModalOpen(true);
  };

  const removeAddress = () => {
    if (selectedAddress) {
      addressContext.updateAddress((prevAddresses) =>
        prevAddresses.filter((address) => address.id !== selectedAddress.id)
      );
      setIsModalOpen(false);
      setSelectedAddress(null);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const editAddress = (address) => {
    navigate("/NewAddress", { state: { address } });
  };

  const addNewAddress = () => {
    navigate("/NewAddress");
  };

  return (
    <div className= "py-5 max-w-51rem border border-black bg-white rounded-sm mt-7 border-opacity-5 shadow-md mx-auto font-Inter p-4 pb-20">
      {isLoading && <Loader />}
      <h6 className="ml-5 mt-2 text-sm">
        Your Account <span className="text-yellow">&gt; Your Address</span>
      </h6>
      <div className="flex justify-between items-center">
        <h2 className="text-17px ml-5 text-yellow font-semibold mb-5 mt-5 md:mb-2">
          Your Address
        </h2>

        <Button
          className={
            "hidden md:block text-yellow py-4 px-4 mr-4 border border-black shadow-md font-semibold rounded-sm border-opacity-35 cursor-pointer mb-7"
          }
          onClick={addNewAddress}
          text="Add Address"
        />
        {error && <p className="text-xs text-red font-Inter">{error}</p>}
      </div>
      {addressContext?.addressList.length > 0 ? (
        <AddressBox
          addressData={addressContext?.addressList}
          editAddress={editAddress}
          confirmRemoveAddress={confirmRemoveAddress}
          setAsDefault={setAsDefault}
        />
      ) : (
        <p className="ml-5 text-sm text-yellow font-semibold mb-5 mt-5 md:mb-2">
          No addresses found. Add a new address using the button above.
        </p>
      )}
      <div className="px-4">
        <Button
          className={
            "block md:hidden text-center text-yellow w-full py-4 border border-black shadow-md font-semibold rounded-sm border-opacity-35 cursor-pointer mt-5"
          }
          onClick={addNewAddress}
          text="Add Address"
        />
      </div>
      {isModalOpen && (
        <Modal
          className={selectedAddress.default && "animate-modal"}
          confirmOnClick={removeAddress}
          cancelOnClick={closeModal}
        />
      )}
    </div>
  );
};

export default Address;
