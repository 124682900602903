import React, { useState, useEffect, useContext } from 'react';
import { cardQuantity, removeCart, getAllItem } from "../../Networking/CartApiAction";
import { ProductContextStates } from '../../Context/ProductContext';
import { Auth } from '../../Context/AuthContext';
import { applyCoupon } from '../../Networking/CouponApisAction';
import productImg3 from '../../assets/cart3.png';
import { placeOrder } from '../../Networking/OrderApiAction';

const OrderCheckoutSection = () => {
  const [orderSummary, setOrderSummary] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [totalPrices, setTotalPrices] = useState({});
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [discount, setDiscount] = useState(0);
  const [couponStatus, setCouponStatus] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const AuthUserContext = useContext(Auth);
  const ProductContextState = useContext(ProductContextStates);

  useEffect(() => {
    if (AuthUserContext.customerId) {
      getAllItem({ requestId: AuthUserContext.customerId }).then(response => {
        const items = response.data.data || [];
        setOrderSummary(items);
        ProductContextState.OrderSummary(items);

        const initialQuantities = {};
        const initialTotalPrices = {};
        items.forEach(item => {
          initialQuantities[item.id] = 1;
          initialTotalPrices[item.id] = item.discountedPrice;
        });
        setQuantities(initialQuantities);
        setTotalPrices(initialTotalPrices);
      });
    }
  }, [AuthUserContext.customerId]);

  const updateQuantity = (id, increment) => {
    setQuantities(prevQuantities => {
      const newQuantity = (prevQuantities[id] || 1) + increment;
      if (newQuantity < 1) return prevQuantities;

      setTotalPrices(prevTotalPrices => ({
        ...prevTotalPrices,
        [id]: newQuantity * orderSummary.find(item => item.id === id).discountedPrice,
      }));

      return {
        ...prevQuantities,
        [id]: newQuantity,
      };
    });
  };

  const handleRemoveItem = async (itemId) => {
    await removeCart({ requestId: itemId });
    setOrderSummary(prev => prev.filter(item => item.id !== itemId));
    setQuantities(prev => {
      const updatedQuantities = { ...prev };
      delete updatedQuantities[itemId];
      return updatedQuantities;
    });
    setTotalPrices(prev => {
      const updatedPrices = { ...prev };
      delete updatedPrices[itemId];
      return updatedPrices;
    });
  };

  const handleCoupon = () => {
    if (isCouponApplied) {
      // Reset coupon state
      setAppliedCoupon('');
      setDiscount(0);
      setCouponStatus(null);
      setSuccessMessage('');
      setIsCouponApplied(false); // Reset the coupon applied state
    } else {
      applyCoupon({ couponCode: appliedCoupon, purchaseAmount: totalPrice })
        .then(response => {
          const data = response.data;
          if (data.code === 200) {
            const discountAmount = data.discountAmount || 0;
            setDiscount(discountAmount);
            setSuccessMessage('Coupon applied successfully!');
            setCouponStatus({ type: 'valid', message: 'Coupon applied successfully!' });
            setIsCouponApplied(true); // Set the coupon applied state
          } else {
            setDiscount(0);
            setSuccessMessage(''); // Clear success message if coupon is invalid
            setCouponStatus({ type: 'invalid', message: data.message });
          }
        })
        .catch(error => {
          console.error('Coupon application failed:', error);
          setDiscount(0);
          setSuccessMessage(''); // Clear success message if there is an error
          setCouponStatus({ type: 'invalid', message: 'Coupon application failed' });
        });
    }
  };

  const totalPrice = orderSummary.reduce((total, item) => total + (totalPrices[item.id] || item.discountedPrice), 0);
  const totalCount = orderSummary.reduce((count, item) => count + (quantities[item.id] || 1), 0);

  const handlePayment = () => {
    const orderSummaryData = orderSummary.map(item => ({
      productId: item.productId,
      sellerId: 1,
      quantity: quantities[item.id] || 1,
    }));
    const bodyData = {
      id: 0,
      orderId: "",
      userId: AuthUserContext.customerId,
      couponId: 1,
      addressId: 1,
      paymentType: 1,
      orderItems: orderSummaryData
    };
    placeOrder(bodyData).then(response => {
      // Handle successful payment here
    });
  };

  return (
    <div>
      {/* Order Items */}
      <div className="bg-white">
        <div className="flex bg-dark-brown p-3 items-center sticky">
          <p className='text-17px uppercase font-semibold text-white px-10'>Review Items</p>
        </div>
        <div className="overflow-auto no-scroll max-h-96">
          {orderSummary.length > 0 ? orderSummary.map(item => (
            <div className="border gap-2 md:gap-4" key={item.id}>
              <div className="lg:grid grid-cols-3 gap-10 pt-3 pb-7 md:px-16">
                <div className="col-span-1 flex flex-col items-center">
                  <p className="text-dark-green font-Inter md:text-xs font-bold">Arriving {item.ArrivingDate}</p>
                  <img className='mt-3 w-24 h-20 object-contain' src={item.image || productImg3} alt={item.brandName} />
                  <div className="flex mt-3">
                    <button className="text-black ps-4 pe-4 border border-black hover:text-white hover:bg-dark-brown font-quicksand font-medium text-13px border-opacity-25" onClick={() => updateQuantity(item.id, -1)}>-</button>
                    <p className="text-black ps-4 pe-4 border border-black font-quicksand font-medium text-13px border-x-slate-300 border-opacity-25">{quantities[item.id] || 1}</p>
                    <button className="text-black ps-4 pe-4 border border-black hover:text-white hover:bg-dark-brown font-quicksand font-medium text-13px border-opacity-25" onClick={() => updateQuantity(item.id, 1)}>+</button>
                  </div>
                </div>
                <div className="col-span-2 flex flex-col items-start">
                  <p className="text-13px">{item.brandName}</p>
                  <p className="text-20px font-bold">Rs. {totalPrices[item.id] || item.discountedPrice}</p>
                  <p className="text-dark-brown font-bold cursor-pointer" onClick={() => handleRemoveItem(item.id)}>Remove</p>
                </div>
              </div>
            </div>
          )) : <p>No items in the cart.</p>}
        </div>
      </div>

      {/* Price Details */}
      <div className="bg-white pb-3 mt-5">
        <div className="p-2">
          <p className='text-18px font-jaldi uppercase font-semibold text-black-opacity-60 px-10'>Price Details</p>
        </div>
        <hr />
        <div className="grid grid-cols-2 px-12">
          <div className="pt-5">
            <p>Price ({totalCount} item{totalCount > 1 ? 's' : ''})</p>
            <p>Tax</p>
            <p>Discount</p>
            <p>Delivery Charges</p>
            <p className='font-semibold'>Total Amount</p>
          </div>
          <div className="pt-5 text-end">
            <p>Rs {totalPrice}</p>
            <p>-</p>
            <p>{discount}</p>
            <p>Free</p>
            <p>Rs {totalPrice - discount}</p>
          </div>
        </div>
        <p className='px-12 p-3 text-dark-green font-semibold'>You will save Rs.{discount} on this order</p>
      </div>

      {/* Coupon Section */}
      <div className="bg-white mt-5 px-12 p-3 pb-7">
        <p>Apply Promo Code</p>
        <div className="mt-3 pb-3 flex ">
          <input
            type="text"
            value={appliedCoupon}
            onChange={(e) => setAppliedCoupon(e.target.value)}
            className='border outline-none'
            disabled={isCouponApplied} // Disable input if the coupon is applied
          />
          <div className='pl-5'>
            <button
              onClick={handleCoupon}
              className={`bg-dark-brown text-white px-5 py-1 ${isCouponApplied ? 'bg-red-500' : ''}`}
            >
              {isCouponApplied ? 'X' : 'Apply'}
            </button>
          </div>
        </div>

        {couponStatus && (
          <div className={`mt-2 ${couponStatus.type === 'valid' ? 'text-green-600' : 'text-red-600'}`}>
            {couponStatus.message}
          </div>
        )}

        {successMessage && (
          <div className="mt-2 text-green-600">
            {successMessage}
          </div>
        )}
      </div>

      {/* Pay Now Button */}
      <div className="mt-5">
        <button className='bg-dark-brown w-full text-20px py-3 text-white' onClick={handlePayment}>Pay Now</button>
      </div>
    </div>
  );
};

export default OrderCheckoutSection;
