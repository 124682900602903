import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getStateAndCity, addressTypes, getAddress } from '../../Networking/AddressApisActions';
import { Auth } from '../../Context/AuthContext';

const AddressCheckoutSection = () => {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [addressTypesList, setAddressTypesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const AuthuserContextState = useContext(Auth);

  // Fetch available cities and states
  useEffect(() => {
    getStateAndCity()
      .then(response => {
        const data = response.data.data;
        setAddresses(data);
      })
      .catch(error => console.error("Error fetching cities and states", error));
  }, []);

  // Fetch user address
  const getUserAddress = () => {
    setLoading(true);
    if (AuthuserContextState.customerId) {
      getAddress(AuthuserContextState.customerId)
        .then(response => {
          const dataResponse = response.data.data;
          if (response.data.code === 200) {
            const defaultAddress = dataResponse.find(item => item.default === true) || dataResponse[0];
            setAddresses(defaultAddress ? [defaultAddress] : []);
          } else {
            setAddresses([]);
          }
        })
        .catch(error => console.error("Error fetching user address", error))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    addressTypes()
      .then(response => {
        const data = response.data.data;
        setAddressTypesList(data);
        getUserAddress(); // Fetch user address after fetching address types
      })
      .catch(error => console.error("Error fetching address types", error));
  }, [AuthuserContextState.customerId]);

  return (
    <div className="pb-3">
      <div className="bg-white">
        <div className="flex bg-dark-brown p-3 items-center">
          <p className="text-16px font-bold flex h-7 justify-start items-center text-white px-10">
            DELIVERY ADDRESS
          </p>
        </div>

        {loading ? (
          <p className="text-xs md:text-15px md:leading-5 text-center py-5">Loading addresses...</p>
        ) : addresses.length > 0 ? (
          addresses.map((address, index) => (
            <React.Fragment key={index}>
              <div className="flex md:flex gap-5 items-center md:px-14 py-7 px-3">
                <div className="relative flex-row flex">
                  <input
                    className="appearance-none rounded-full h-5 w-5 border-2 border-dark-brown cursor-pointer"
                    type="radio"
                    name="address"
                    id={`address-radio-${index}`}
                    defaultChecked={index === 0}
                  />
                  <span className="custom-radio-dot"></span>
                </div>
                <div className="md:row flex flex-row">
                  <p className="text-xs md:text-15px md:leading-5 mr-2">
                    <span className="font-bold">{address.name}</span>
                    <span className="block">
                      {address.addressLine1}, {address.addressLine2}
                    </span>
                    <span className="block">
                      {address.city} {address.state} - {address.pincode}
                    </span>
                  </p>
                </div>
              </div>
              {index < addresses.length - 1 && (
                <hr className="my-4 border-dark-brown" />
              )}
            </React.Fragment>
          ))
        ) : (
          <p className="text-xs md:text-15px md:leading-5 text-center py-5">
            No addresses available
          </p>
        )}

        <div className="flex justify-end py-3 px-3">
          <Link to={'/Address'}>
            <button
              className="border md:w-fit px-5 h-8 bg-dark-brown text-sm text-white font-light font-Inter"
            >
              Change Address
            </button>
          </Link>
        </div>
      </div>

      <div className="bg-white mt-5">
        <Link to={'/NewAddress'}>
          <p
            className="font-inter font-bold text-15px text-dark-brown md:px-50px md:py-5 w-full cursor-pointer"
          >
            + Add a New Address
          </p>
        </Link>
      </div>
    </div>
  );
};

export default AddressCheckoutSection;
