import React, { useContext, useEffect, useState } from "react";
import LoginCheckoutSection from "./LoginCheckoutSection";
import AddressCheckoutSection from "./AddressCheckoutSection";
// import PaymentCheckoutSection from "./PaymentCheckoutSection";
import OrderCheckoutSection from "./OrderCheckoutSection";
import Navbar from "../Navbar";
import PaymentOption from "./PaymentOption";
import { Auth } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ProductContextStates } from "../../Context/ProductContext";

const CheckoutComponent = () => {
    const [customerIdData, setCustomerIdData] = useState([])
    const AuthUserContextData = useContext(Auth);
    const navigate = useNavigate()
    useEffect(() => {
        setCustomerIdData(AuthUserContextData.customerId)
        if (customerIdData && customerIdData === 0) {
            navigate('/login')
        }
    }, [customerIdData])

   
    
    return (
        <>
            {/* <Navbar /> */}
            < div className="bg-Background-gray  ps-10 pe-10 pt-3 pb-6 min-h-screen max-h-full" >
                <div className="grid md:grid-cols-5 justify-start gap-3 ">
                    <div className="md:col-span-3 ">
                        <div className={customerIdData != 0 ? "hidden" : 'block'}><LoginCheckoutSection /></div>
                        <div className="border pb-3"><AddressCheckoutSection /></div>
                        <div className=""><PaymentOption /></div>
                    </div>
                    <div className="md:col-span-2 space-y-3">
                        <div className="border"><OrderCheckoutSection /></div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default CheckoutComponent;