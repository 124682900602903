import React, { useContext, useEffect, useState } from "react";
import { Star, CheckTick, CheckBox, ShareIcon, Cart, Buy, Tag, Like, Dislike, Plus, Circletick, Add, Equal, Heart, heartwhite, heartblack, heartbrown } from "../../assets/svg/index";
import HappiloProduct5 from "../../assets/image 84.png";
import { addToCart, getProductDetails } from "../../Networking/ProductApisAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RatingComponent from "../../acscomponents/RatingComponent"
import { RatingreviewData } from './RatingData'
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import { addFavourited, deleteFavourited, getAllfavourited } from "../../Networking/FavouriteApisAction";

import dryfruit from '../../assets/dryfruit1.png'
import subimg1 from '../../assets/subimg1.png'
import subimg2 from '../../assets/subimg2.png'


const ProductDetails = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [showFullTexts, setShowFullTexts] = useState([]);
  const [showFull, setShowFull] = useState(false);
  const location = useLocation();
  const [productDetailsData, setProductDetailsData] = useState(null);
  const [message, setMessage] = useState("")
  const [errMessage, setErrMessage] = useState("")
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [checkList, setCheckList] = useState(false);
  const [productData, setproductData] = useState([]);
  const [isFavourite, setIsFavourite] = useState(true)


  const { selectedProductId, categoryId } = location.state;
  const AuthUserContextStates = useContext(Auth)

  const navigate = useNavigate()

  const ProductAvailableOffers = [
    "Lorem iporem ipsquenatoquein nun natoquein nunc sit sed natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed in nunc sit sed natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed in nunc sit sed natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed in nunc sit sed natoque",
  ];

  const toggleShowFullText = (index) => {
    const newShowFullTexts = [...showFullTexts];
    newShowFullTexts[index] = !newShowFullTexts[index];
    setShowFullTexts(newShowFullTexts);
  };

  const [products, setProducts] = useState([
    { id: 1, name: 'Xbox FanFest', size: 'Black/XS', price: 69.99, checked: true },
    { id: 2, name: 'HCS 3', size: 'Black/One Size', price: 22.49, oldPrice: 29.99, checked: true },
    { id: 3, name: 'Xbox', size: 'White/XS', price: 99.99, checked: true }
  ]);

  const totalPrice = products.reduce((total, product) => (product.checked ? total + product.price : total), 0);

  const toggleProduct = (id) => {
    setProducts(products.map((p) => (p.id === id ? { ...p, checked: !p.checked } : p)));
  };

  const items = [
    {
      name: "Happilo 100% Natural Premium Almonds (900g)",
      rating: 4.5,
      imageSrc: "img3.jpg",
      price: "500",
    },
    {
      name: "Happilo 100% Natural Premium Almonds (900g)",
      rating: 4.5,
      imageSrc: "img1.jpg",
      price: "250",
    },
    {
      name: "Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)",
      rating: 4.5,
      imageSrc: "img3.jpg",
      price: "300",
    },
  ];

  const quantityOptions = ["100 g", "200 g", "250 g", "500 g"];


  useEffect(() => {
    if (selectedProductId) {
      const bodyData = {
        requestId: selectedProductId,
        userId: AuthUserContextStates.customerId,
      }
      getProductDetails(bodyData).then(response => {
        const data = response.data.data
        if (data) {
          setProductDetailsData(data);
          setproductData(data);
        }
      })
    }

  }, [selectedProductId])

  const handleCheckOut = (id) => {
    setLoading(true)
    setCheckList(prevState => {
      const newCheckList = {
        ...prevState, [id]: !prevState[id]
      }
      return newCheckList;
    })  

    if (isFavourite[id] === true) {
      const bodyData = {
        requestId: id,
        userId: AuthUserContextStates.customerId,
      }
      deleteFavourited(bodyData).then(response => {
        const data = response.data.data
      })

    } else {
      const bodyData = {
        userId: AuthUserContextStates.customerId,
        productId: productData.id,
        category: productData.productCategoryId,
        subCategory: productData.subCategoryId,
        productCategory: productData.productCategoryId,
        brand: productData.brandId,
        color: productData.colorId,
        unit: productData.unitId,
        productSize: productData.sizeId,
      };
      addFavourited(bodyData).then(response => {
        const data = response.data.data
      })
    }

    setLoading(false)
  };


  const handleAddCart = (selectedProductId) => {
    setLoading(true)

    if (AuthUserContextStates.customerId === 0) {
      navigate('/login')
    } else {
      const bodyData = {
        userId: AuthUserContextStates.customerId,
        productId: selectedProductId,
        category: categoryId,
        subCategory: productData.subCategoryId,
        productCategory: productData.productCategoryId,
        brand: productData.brandId,
        color: productData.colorId,
        unit: productData.unitId,
        productSize: productData.sizeId,
        quantity: 1,
        active: true
      };

      addToCart(bodyData).then(res => {
        if (res.data.code === 200) {
          setMessage(res.data.message)
          setTimeout(() => setMessage(""), 3000)
          setLoading(false)
          navigate('/Cartpage')
        }
        if (res.data.code === 201) {
          setErrMessage(res.data.message)
          setTimeout(() => setErrMessage(""), 3000)
          setLoading(false)
        }
      })
    }

  }
  const [mainImage, setMainImage] = useState(productDetailsData?.mainImage || productData.mainImage);

  const items1 = [
    {
      id: 1,
      name: "Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)",
      imageSrc: "img3.jpg",
      rating: 3,
      score: "Average",
    },
    {
      id: 2,
      name: "Happilo 100% Natural Premium Almonds (900g)  Happilo 100% Natural Premium Almonds (900g)Happilo 100% Natural Premium Almonds (900g)",
      imageSrc: "img4.jpg",
      rating: 4,
      score: "Good",
    },
  ];
  const handleIconClick = (itemId) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [itemId]: (prevCounts[itemId] || 0) + 1,
    }));
  };

  console.log(productData, "productData");

  return (
    <>
      {loading && (
        <Loader className={loading ? "blur" : "blur-none"} />
      )}
      <div className='bg-white '>

        <div className="border">
          {productData && (
            <div>
              <div className="flex gap-3 justify-end px-5">
                <p className='flex '><img src={ShareIcon} alt="Share" /><span className='px-2'>Share</span></p>
                {/* <p className='flex ' onClick={() => setIsChecked(!isChecked)}>
                    <img src={isChecked ? CheckTick : CheckBox} alt="Compare" />
                    <span className='px-2'>Compare</span>
                  </p> */}
              </div>
              <div className={`grid px-5 gap-5 md:h-[calc(100vh-11vh)] ${productData && productData.images && productData.images.length > 0 ? "md:grid-cols-5" : "md:grid-cols-4 "}`}>

                {/* Multiple images */}
                {productData && productData.images && productData.images.length > 0 && (
                  <div className="col-span-2 md:col-span-1 w-fit overflow-y-auto md:grid md:h-[calc(100vh-11vh)] md:order-1 order-2 flex md:flex-col flex-row justify-center md:px-8 md:justify-start items-center gap-2 no-scroll">
                    {productData.images.map((image, index) => (
                      <div
                        key={index}
                        className="border cursor-pointer md:my-1.5 my-0.5 mx-0.5 md:mx-0 p-2 "
                        onClick={() => setMainImage(image)}
                      >

                        <img
                          src={image}
                          alt={`Product ${index + 1}`}
                          className="object-contain md:h-32 md:w-40 h-20 w-24"
                        />
                      </div>
                    ))}
                  </div>
                )}

                {/* Main product image */}
                <div className="col-span-2 h-max  md:order-2 order-1  md:p-2  ">
                  <p className="flex justify-end items-start px-5 cursor-pointer">
                    {/* <img
                      src={productData.fav ? heartbrown : heartblack}
                      className={`${productData.favourite ? "opacity-100" : "opacity-40"}`}
                      onClick={() => {
                        handleCheckOut(productData.id);
                      }}
                    /> */}
                    <img
                            src={isFavourite[productData.id] ? heartbrown : heartblack}
                            className={`absolute right-5 cursor-pointer ${productData.inCart ? "opacity-0" : isFavourite[productData.id] ? "opacity-100" : "opacity-40"}`}
                            onClick={() => handleCheckOut(productData.id)}
                          />
                  </p>
                  <div className=" flex justify-center items-center md:h-[calc(100vh-20vh)] h-[calc(100vh - 50vh md:px-5 ">
                    <div className="">
                      <img src={productData.image ? productData.image : null} alt="Main Product" className="w-96 md:h-96 object-contain )]" />
                    </div>
                  </div>
                  <div className="flex gap-3 px-3 md:px-0 ">
                    <button
                      type="button"
                      className="w-full h-12 font-jaldi mb-5 lg:mb-0 text-md  md:text-14px lg:text-16px bg-dark-brown text-white rounded-sm font-bold border-none flex items-center justify-center px-3"
                      style={{
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleAddCart(productData.productId)}
                    >
                      <img src={Cart} alt="" className="mr-5" />
                      ADD TO CART
                    </button>
                    <button
                      type="button"
                      className="w-full h-12  font-jaldi text-md  md:text-14px lg:text-16px bg-bismi-yellow  text-white rounded-sm font-bold border-none  flex items-center justify-center px-3"
                      style={{
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <img src={Buy} alt="" className="mr-5" />
                      BUY NOW
                    </button>
                  </div>
                </div>

                {/* Product details */}
                <div className="col-span-2   h-[calc(100vh-11vh)] p-4  md:order-3 order-3 overflow-y-scroll no-scroll">
                  <p className="text-20px mt-1 font-jaldi font-bold opacity-80 mb-2">
                    {productData.productName ? productData.productName : null} ({productData.size ? productData.size : null})
                  </p>
                  <div className="flex items-center mb-2">
                    <p className="inline-flex bg-lightgreen px-2 py-1 text-13px mr-2 rounded-sm">
                      <span className="text-13px font-bold text-green flex font-quicksand items-center">
                        <img src={Star} alt="" className="mr-2 -ml-1" /> {productData.rating ? productData.rating : null}
                      </span>
                    </p>
                    <p className="inline-block font-quicksand text-13px opacity-60">
                      12,925 Ratings & 1,064 Reviews
                    </p>
                  </div>
                  <p className="text-dark-green text-15px mt-3 mb-1 font-quicksand opacity-90 font-bold">
                    Special Price
                  </p>
                  <p className="text-25px font-bold font-quicksand text-black">
                    Rs.{productData.priceWithGst }
                  </p>
                  <p className="text-16px font-quicksand text-black font-semibold mt-3 mb-3">
                    Available Offers
                  </p>
                  <div className="text-13px mb-2 max-w-full overflow-wrap break-word font-quicksand text-black-opacity-60% ">
                    {ProductAvailableOffers.map((offer, index) => (
                      <div className="flex items-start mb-5" key={index}>
                        <img src={Tag} alt="" className="" />
                        <p className="ml-4">{offer}</p>
                      </div>
                    ))}
                    <div className="">
                      <div className="flex  gap-9 ">
                        <p className="text-16px  text-black-opacity opacity-90 font-QuattrocentoSans font-bold">
                          Delivery
                        </p>
                        <div className="grid">
                          <p className="font-QuattrocentoSans text-16px  font-bold opacity-50 cursor-pointer">
                            {showFullText
                              ? "Lorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur"
                              : "Lorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur".slice(
                                0,
                                100
                              )}
                          </p>
                          <p
                            className="'text-24px  text-dark-brown font-bold  font-quicksand cursor-pointer  '"
                            onClick={() => setShowFullText(!showFullText)}
                          >
                            {showFullText ? "Hide Details" : "View Details"}
                          </p>
                        </div>
                      </div>

                      <div className="grid mt-3 w-full">
                        {/* <div className="flex gap-3 justify-start items-center py-1">
                            <p className="text-16px  text-black-opacity opacity-90  font-QuattrocentoSans font-bold">
                              Quantity
                            </p>
                            <p className=" font-bold font-QuattrocentoSans text-16px ">
                              {productData.quanity}
                            </p>
                          </div> */}
                        {/* <div className="flex gap-9 mt-5">
                            <p className="text-16px  opacity-90 font-QuattrocentoSans font-bold">
                              Quantity
                            </p>
                            <p className="">
                              {quantityOptions.map((quantity, index) => (
                                <span
                                  key={index}
                                  className={`inline-block font-QuattrocentoSans border border-black-opacity-50% px-4 py-2  mr-1  rounded-sm ${selectedQuantity === quantity
                                    ? "bg-dark-brown text-white font-bold"
                                    : "bg-transparent text-black font-bold"
                                    } cursor-pointer`}
                                  onClick={() => setSelectedQuantity(quantity)}
                                >
                                  {quantity}
                                </span>
                              ))}
                            </p>
                          </div> */}

                        <div className="flex flex-col my-5 lg:flex-row text-16px font-QuattrocentoSans font-bold  justify-between">
                          <div className="flex">
                            <p className="opacity-90">Highlights</p>
                            <div className="ml-7 opacity-70 text-black">
                              <p>-Raw</p>
                              <p className="text-wrap">-Comes in a Pouch</p>
                            </div>
                          </div>
                          <div className="flex my-5 lg:my-0 lg:ms-4 ">
                            <p className="mr-12 opacity-90">Services</p>
                            <p className="text-wrap  opacity-70 text-black">
                              Cash on delivery available
                            </p>
                          </div>
                        </div>
                        <div className="flex  mb-2 justify-start">
                          <div className="flex flex-1  ">
                            <p className="text-16px  opacity-90 font-QuattrocentoSans font-bold ">
                              Seller
                            </p>
                            <p className="opacity-90 font-bold text-dark-brown  font-QuattrocentoSans text-16px mr-15 ml-[58px] mb-3 break-words max-w-[300px]">
                              Super ComNet
                            </p>
                          </div>
                        </div>
                        <div className=" gap-8 ml-16">
                          <p className="opacity-70 font-bold text-black font-QuattrocentoSans text-16px ml-8 break-words max-w-[300px]">
                            -No Returns Allowed
                          </p>
                          <p className="opacity-70 font-bold text-black font-QuattrocentoSans text-16px ml-8 break-words max-w-[300px]">
                            -GST Invoice Available
                          </p>
                        </div>

                        <div className="flex  gap-6 mt-5 ">
                          <p className="text-16px  opacity-90 font-QuattrocentoSans font-bold">
                            Description
                          </p>
                          <div className="grid">
                            <p className="font-QuattrocentoSans text-16px font-medium  -ml-1  ">
                              {showFull
                                ? "Lorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur"
                                : "Lorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur".slice(
                                  0,
                                  100
                                )}
                            </p>
                            <p
                              className="'text-16px  text-dark-brown font-bold  font-quicksand cursor-pointer  -ml-1  '"
                              onClick={() => setShowFull(!showFull)}
                            >
                              {showFull ? "Hide Details" : "View Details"}
                            </p>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}


          <div className="grid md:grid-cols-5  px-5 py-5 gap-2">
            <div className="col-span-3 order-1">
              <RatingComponent RatingreviewData={RatingreviewData} />

              <div className="flex flex-col md:mb-10 py-2" >
                {items1.map((item, index) => (
                  <div
                    key={item.id}
                    className="flex flex-col p-5 mb-2 border-b bg-white border border-gray-200"
                  >
                    {item.rating && (
                      <div className="flex items-center">
                        <div className="bg-lightgreen font-quicksand px-2 py-1 text-13px font-bold rounded-sm flex items-center">
                          <img
                            src={Star}
                            alt=""
                            className="mr-3 text-dark-green"
                          />
                          <span className="text-10px font-bold  text-green gap-5 flex items-center">
                            {item.rating}
                          </span>
                        </div>
                        <p className="ml-2 font-quicksand font-bold text-13px text-xs">
                          {item.score}
                        </p>
                      </div>
                    )}
                    <p className="opacity-40 font-quicksand font-semibold text-13px mt-4">
                      {item.name}
                    </p>
                    <img
                      src={HappiloProduct5}
                      alt={item.name}
                      className="w-28 h-28 mt-5 object-cover mb-2"
                    />

                    <div className="flex mt-2 ">
                      <div className="opacity-20 ">
                        <img src={Circletick} alt="" className="" />
                      </div>
                      <div className="opacity-40 font-quicksand font-semibold text-13px ml-2">
                        <p>Certified Buyer , 29 April, 2023</p>
                      </div>
                    </div>
                    <div className="flex justify-end mr-8">
                      <div className="-mt-10 flex">
                        <div
                          className="opacity-60 cursor-pointer"
                          onClick={() => handleIconClick(`first-${item.id}`)}
                        >
                          <img src={Like} alt="" className="" />
                        </div>

                        <div className="opacity-50 font-quicksand font-10px font-semibold ml-1 -mt-1">
                          <p>{counts[`first-${item.id}`] || 0}</p>
                        </div>

                        <div
                          className="flex items-center cursor-pointer ml-2 mr-2 opacity-60 -mt-4"
                          onClick={() => handleIconClick(`second-${item.id}`)}
                        >
                          <img src={Dislike} alt="" className="-mt-1" />
                        </div>

                        <div className="opacity-50 font-quicksand font-10px font-semibold -mt-1">
                          <p>{counts[`second-${item.id}`] || 0}</p>
                        </div>
                      </div>
                    </div>

                    {index === items1.length - 1 && (
                      <div className="flex justify-end font-inter text-13px mt-5">
                        <Link to={'/ProductReview'}>
                          <button className="px-4 py-1 mr-3 border text-dark-brown font-Inter font-bold-900 border-gray-400 rounded">
                            View all Reviews
                          </button>
                        </Link>

                      </div>
                    )}
                  </div>
                ))}
              </div>

            </div>
            <div className="col-span-2 order-2">
              <div className="  md:flex-row  ">
                <div className="p-1 bg-white w-full   ">
                  <p className="py-1 text-23px md:text-25px font-semibold font-QuattrocentoSans text-dark-brown px-2">
                    Specification
                  </p>
                </div>
                <div className="p-1 bg-white w-full  px-4 py-2">
                  <p className=" text-lg md:text-23px font-QuattrocentoSans text-black ">
                    In The Box
                  </p>
                  <div className="grid grid-cols-2 text-md md:text-xl pt-2">
                    <p className=" font-normal font-QuattrocentoSans text-black opacity-60 ">
                      Pack Of
                    </p>
                    <p className=" font-normal font-QuattrocentoSans text-black opacity-60 ">
                      1
                    </p>
                  </div>
                </div>
                <div className="p-1 bg-white w-full  px-4 py-2">
                  <p className="text-xl md:text-23px font-QuattrocentoSans text-black ">
                    General
                  </p>

                  <div className="grid grid-cols-2 gap-2 text-lg md:text-xl font-normal font-QuattrocentoSans opacity-60 text-black py-2 ">
                    <p>Brand</p>
                    <p>Happilo</p>
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-lg md:text-xl font-normal font-QuattrocentoSans opacity-60 text-black py-2 ">
                    <p>Quality</p>
                    <p>900g</p>
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-lg md:text-xl font-normal font-QuattrocentoSans opacity-60 text-black py-2 ">
                    <p>Type</p>
                    <p>Almonds</p>
                  </div>
                </div>
              </div>
              <div className="bg-white">
                <h3 className="text-lg sm:text-lg md:text-2xl lg:text-[25px] ml-2 sm:ml-3 mb-1 sm:mb-2 mt-1 sm:mt-2 font-QuattrocentoSans font-semibold text-dark-brown">
                  Frequently bought together
                </h3>
              </div>
              {/* {productData.similarProducts && productData.similarProducts.length > 0 ? (
                <div className="w-full overflow-x-hidden  flex justify-around">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5 ml-0 bg-white rounded-sm text-wrap">
                    {productData.similarProducts.map((item, index) => (
                      <div key={item.id} className="flex flex-col items-center p-2">
                        <p key={item.id} className="flex justify-center items-center p-3">
                          <img
                            src={item.productImage}
                            alt={item.productName}
                            className="w-24 h-24 object-cover mb-1"
                          />
                          {index < productData.similarProducts.length - 1 && (
                            <p className="text-19px pl-10">+</p>
                          )}
                        </p>
                        <div className="text-start mt-2">
                          <p className="text-sm sm:text-base font-semibold text-nowrap">{item.productName}</p>
                          <p className="inline-flex bg-lightgreen px-2 py-1 text-13px mr-2 rounded-sm mt-1">
                            <span className="text-13px font-bold text-green flex font-quicksand items-center">
                              <img src={Star} alt="star" className="mr-2 -ml-1" /> {item.ratings || 'N/A'}
                            </span>
                          </p>
                          <div className="flex justify-center items-center mt-1">
                            <p className="text-lg text-nowrap sm:text-xl font-bold text-red font-jaldi">
                              Rs. {item.price}
                            </p>
                            {index < productData.similarProducts.length - 1 && (
                              <p className="text-19px text-black pl-10">+</p>
                            )}

                            <div className="">
                              Total: Rs. {productData.similarProducts.reduce((total, item) => total + item.price, 0)}
                            </div>
                          </div>

                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              ) : null} */}
              <div className="p-4 md:p-6 flex flex-col md:flex-row md:justify-between items-start bg-white rounded-lg shadow-lg space-y-4 md:space-y-0">
      
      <div className='flex flex-col items-center md:items-start'>
        <div className="flex items-center justify-center md:justify-start space-x-4 pb-4 md:pb-6 lg:pb-10 px-2 md:px-6 lg:px-10">
          <img src={dryfruit} alt="Product 1" className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 object-cover rounded-lg" />
          <span className="text-xl md:text-2xl lg:text-3xl font-bold">+</span>
          <img src={subimg1} alt="Product 2" className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 object-cover rounded-lg" />
          <span className="text-xl md:text-2xl lg:text-3xl font-bold">+</span>
          <img src={subimg2} alt="Product 3" className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 object-cover rounded-lg" />
        </div>

        <div className="ml-2 md:ml-8 lg:ml-12">
          {products.map((product) => (
            <div key={product.id} className="flex items-center space-x-2 md:space-x-4 mb-3 md:mb-4">
              <input
                type="checkbox"
                checked={product.checked}
                onChange={() => toggleProduct(product.id)}
                className="w-4 h-4 border-gray-300 rounded focus:ring-brown-500"
                style={{
                  backgroundColor: product.checked ? '#A52A2A' : 'white', 
                  borderColor: '#A52A2A',
                }}
              />
              <span className="text-gray-700 text-xs md:text-sm lg:text-base">{product.name}</span>
              <select className="border-gray-300 rounded-md shadow-sm focus:ring-offset-amber-900 focus:border-b-red-900 text-xs md:text-sm lg:text-base">
                <option>{product.size}</option>
              </select>
              <span className="text-amber-900 font-bold text-xs md:text-sm lg:text-base">
                {product.oldPrice && (
                  <span className="line-through text-gray-500 mr-1 lg:mr-2">Rs. {product.oldPrice.toFixed(2)}</span>
                )}
                Rs. {product.price.toFixed(2)}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="text-right md:pr-4 lg:pr-[500px] pt-4 md:pt-10 lg:pt-20">
        <p className="text-gray-700 text-xs md:text-sm lg:text-base mb-2">
          Total price: <span className="text-amber-900 font-bold text-sm md:text-base lg:text-lg">${totalPrice.toFixed(2)}</span>{' '}
          <span className="line-through text-gray-500 text-xs md:text-sm lg:text-base">Rs . 182.47</span>
        </p>
        <button className="bg-amber-900 text-white px-3 py-2 md:px-4 md:py-2 lg:px-5 lg:py-3 rounded-md hover:bg-amber-900 focus:outline-none text-xs md:text-sm lg:text-base">
          ADD TO CART
        </button>
      </div>
    </div>

            </div>
          </div>


        </div>
      </div>


    </>
  );
}

export default ProductDetails;
