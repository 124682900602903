import React, { useState, useEffect } from "react";
import Img1 from "../../assets/Rectangle 390.png";
import Img2 from "../../assets/Rectangle 409.png";
import { SearchIcon } from "../../assets/svg/index";
import { TickIcon } from "../../assets/svg/index";
import {getOrderItems} from "../../Networking/OrderApiAction"
import { Link } from "react-router-dom";
import Navbar  from "../Navbar";
const Orderspage = () => {
  const heading = "Home > My Account >";
  const [content, setContent] = useState(null);
  const [allOrderItems ,setAllOrderItems] =useState([])

  const [orderstatus, setorderstatus] = useState([
    {
      orderid: 1,
      orderstatustype: "On the way",
      status: false,
    },
    {
      orderid: 2,
      orderstatustype: "Delivered",
      status: false,
    },
    {
      orderid: 3,
      orderstatustype: "Cancelled",
      status: false,
    },
    {
      orderid: 4,
      orderstatustype: "Returned",
      status: false,
    },
  ]);


  useEffect(() => {
    fetchGetOrderItems();
  }, []);

  const fetchGetOrderItems = () => {
    const bodyData = {
      requestId: 1,
      userId: 0,
    };

    getOrderItems(bodyData).then((response) => {
      const data = response.data.data;
      setAllOrderItems(data);
      console.log(" Get Order Items", data);
    }).catch(error => console.error('Error fetching order items:', error));
  };






  const handleOrderStatus = (id) => {
    setorderstatus(
      orderstatus.map((itm) =>
        itm.orderid === id ? { ...itm, status: !itm.status } : itm
      )
    );
  };

  const [ordertime, setordertime] = useState([
    {
      ordertimeid: 1,
      ordertimetype: "Last 30 days",
      ordertimestatus: false,
    },
    {
      ordertimeid: 2,
      ordertimetype: "2020",
      ordertimestatus: false,
    },
    {
      ordertimeid: 3,
      ordertimetype: "2021",
      ordertimestatus: false,
    },
    {
      ordertimeid: 4,
      ordertimetype: "2022",
      ordertimestatus: false,
    },
  ]);

  const handleTimeStatus = (id) => {
    setordertime(
      ordertime.map((orderitm) =>
        orderitm.ordertimeid === id
          ? { ...orderitm, ordertimestatus: !orderitm.ordertimestatus }
          : orderitm
      )
    );
  };

  const [product, setProduct] = useState([
    {
      id: 1,
      ProductImg1: Img1,
      ProductName:
        "Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried Berries Mix Dried Berries Mix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried Berries Mix Dried Berries Dried BerriesMix Dried BerriesMix Dried BerriesMix",
      Amount: "300.00",
      Delivered: "Oct 25,2020",
      Progress:
        "Your item  has been delivered t 25,2020  Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried BerriesMix Dried BerriesMix",
    },
    {
      id: 2,
      ProductImg1: Img2,
      ProductName:
        "Black Wallpaper Roll ed Berries Mix Dried Berries Mix Dried Berries Mix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried Berries Mix Dried Berries Mix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried Berries Mix Dried BerriesMix Dried BerriesMix Dried BerriesMix Dried Berries Mix Dried Berries Dried BerriesMix Dried BerriesMix Dried BerriesMix Dri",
      Amount: "200.00",
      Delivered: "Oct 25,2020",
      Progress: "Your item  has been delivered",
    },
    {
      id: 3,
      ProductImg1: Img2,
      ProductName: "Black Wallpaper Roll",
      Amount: "200.00",
      Delivered: "Oct 25,2020",
      Progress: "Your item  has been delivered",
    },
    {
      id: 4,
      ProductImg1: Img2,
      ProductName: "Black Wallpaper Roll",
      Amount: "200.00",
      Delivered: "Oct 25,2020",
      Progress: "Your item  has been delivered",
    },
    {
      id: 5,
      ProductImg1: Img2,
      ProductName: "Black Wallpaper Roll",
      Amount: "200.00",
      Delivered: "Oct 25,2020",
      Progress: "Your item  has been delivered",
    },
    {
      id: 6,
      ProductImg1: Img1,
      ProductName: "Mix Dried Berries",
      Amount: "300.00",
      Delivered: "Oct 25,2020",
      Progress: "Your item  has been delivered",
    },
    {
      id: 7,
      ProductImg1: Img2,
      ProductName: "Black Wallpaper Roll",
      Amount: "200.00",
      Delivered: "Oct 25,2020",
      Progress: "Your item  has been delivered",
    },
    {
      id: 8,
      ProductImg1: Img1,
      ProductName: "Mix Dried Berries",
      Amount: "300.00",
      Delivered: "Oct 25,2020",
      Progress: "Your item  has been delivered",
    },
  ]);

  const handleContent = (id) => {
    setContent(id);
  };
  return (
    <>

      <div>
        <div className="flex justify-evenly mt-12">
          <div className="flex flex-col md:flex-row gap-5 md:gap-10 lg:px-20 md:px-10">
            <div className="flex flex-col">
              <div className="flex flex-row shadow-2xl bg-white pl-38px pr-36">
                <h5 className="font-semibold font-Inter text-15px text-dark-brown tracking-wide pt-2 pb-2">
                  Filters
                </h5>
              </div>
              <div className="flex flex-row shadow-2xl bg-dark-brown pl-38px  mt-5">
                <h5 className="tracking-wide text-white font-Inter font-semibold text-15px pt-2 pb-2">
                  ORDER STATUS
                </h5>
              </div>
              <div className="flex flex-col shadow-2xl bg-white pl-38px pb-3">
                {orderstatus.map((orderstate) => (
                  <div
                    className="flex flex-col gap-y-3 py-0 font-Inter font-semibold text-12px text-black-opacity-60"
                    key={orderstate.orderid}
                  >
                    <div className="flex gap-x-2 pt-3">
                      {!orderstate.status ? (
                        <div
                          className="border border-slate-700 opacity-35 w-18px h-18px cursor-pointer"
                          onClick={() => {
                            handleOrderStatus(orderstate.orderid);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="border border-slate-700 opacity-35 w-18px h-18px cursor-pointer"
                          onClick={() => {
                            handleOrderStatus(orderstate.orderid);
                          }}
                        >
                          <img
                            className="w-10"
                            src={TickIcon}
                            alt="Tick Icon"
                          />
                        </div>
                      )}

                      {orderstate.orderstatustype}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-row shadow-2xl bg-dark-brown pl-38px">
                <h5 className="tracking-wide text-white font-Inter font-semibold text-15px pt-2 pb-2">
                  ORDER TIME
                </h5>
              </div>
              <div className="flex flex-col shadow-2xl bg-white pl-38px pb-6">
                {ordertime.map((ordertimestate) => (
                  <div
                    className="flex flex-col gap-y-3 py-0 font-Inter font-semibold text-12px text-black-opacity-60"
                    key={ordertimestate.ordertimeid}
                  >
                    <div className="flex gap-x-2 pt-3">
                      {!ordertimestate.ordertimestatus ? (
                        <div
                          className="border border-slate-700 opacity-35 w-18px h-18px cursor-pointer"
                          onClick={() => {
                            handleTimeStatus(ordertimestate.ordertimeid);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="border border-slate-700 opacity-35 w-18px h-18px cursor-pointer"
                          onClick={() => {
                            handleTimeStatus(ordertimestate.ordertimeid);
                          }}
                        >
                          <img
                            className="w-10"
                            src={TickIcon}
                            alt="Tick Icon"
                          />
                        </div>
                      )}

                      {ordertimestate.ordertimetype}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col ">
              <div>
                <p className="font-Inter text-15px tracking-wide mb-2">
                  {heading}
                  <span className="text-dark-brown font-medium">My Orders</span>
                </p>
                
                <div className="flex flex-row flex-1">
                  <div className="flex w-full">
                    <input
                      className=" h-10 ps-6 pe-6 focus:outline-none font-Inter text-13px flex-1"
                      type="text"
                      name=""
                      id=""
                      placeholder="Search your Orders Here"
                    />

                    <button className="bg-dark-brown text-white flex items-center pe-3 pt-1 pb-1 font-Inter font-bold text-10px md:text-13px">
                      <img
                        className="w-4 h-4 mt-1 ml-10px mr-1"
                        src={SearchIcon}
                        alt=""
                      />
                      Search Orders
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-6 flex-1 overflow-y-scroll h-screen md:h-580px no-scroll w-20rem md:w-max lg:w-30rem  xl:w-[50rem]">
                {allOrderItems.length > 0 ? allOrderItems.map((products) => (
                    <div key={products.id} className="flex flex-col md:flex-row items-center bg-white shadow-md border rounded ps-5 justify-between">
                      <div className="pt-5 pb-5 flex flex-1 flex-shrink-0">
                        <img
                          className="w-32"
                          src={Img1}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col flex-1 py-4 pl-2">
                        <p
                          onClick={() => {
                            handleContent(products.id);
                          }}
                          className={`font-Inter pr-5 text-justify font-medium text-13px text cursor-pointer ${
                            content !== products.id ? "line-clamp-2" : ""
                          }`}
                        >
                          {products.productName}
                        </p>
                        <p className="text-red font-Inter font-medium text-12px pt-1">
                          Rs. {products.price}
                        </p>
                      </div>
                      <div className="flex flex-col flex-1 justify-end ps-5 py-4 pe-5">
                        <div className="flex flex-row">
                          <div className="border p-1 h-1 rounded-full bg-green me-3 mt-1"></div>
                          <p className="font-Inter font-medium text-12px">
                            Delivered On {products.createdAt}
                          </p>
                        </div>
                        <p className="font-Inter font-normal text-12px opacity-50 ms-5 pt-1">
                          {products.category}
                        </p>
                        <p className="font-quicksand font-medium text-13px text-dark-brown ms-5 pt-1 cursor-pointer">
                          <Link to='/Orderdetails'>View details</Link> 
                        </p>
                      </div>
                    </div>
                  )) : 
                  
                  <div className="flex flex-col gap-4 mt-6 flex-1 overflow-y-scroll h-screen md:h-580px no-scroll">
                    <div className="flex flex-col md:flex-row items-center bg-white shadow-md border rounded ps-5 justify-center w-320px md:w-900px">
                      <div className="flex flex-1 justify-center items-center py-2 font-bold opacity-60%">
                        No data Found
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orderspage;
