import React, { useCallback, useEffect, useRef, useState } from "react";

const PriceSeeker = ({ min, max, onMinValueChange, onMaxValueChange }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minRef = useRef(min);
  const maxRef = useRef(max);
  const range = useRef(null);

  const percentage = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    const minPercentage = percentage(minVal);
    const maxPercentage = percentage(maxRef.current);

    if (range.current) {
      range.current.style.left = `${minPercentage}%`;
      range.current.style.width = `${maxPercentage - minPercentage}%`;
    }
  }, [minVal, percentage]);

  useEffect(() => {
    const minPercentage = percentage(minRef.current);
    const maxPercentage = percentage(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercentage - minPercentage}%`;
    }
  }, [maxVal, percentage]);

  const handleMinChange = (e) => {
    const value = Math.min(e.target.value, maxVal - 1);
    setMinVal(value);
    minRef.current = value;
    if (onMaxValueChange) {
      onMinValueChange(value)
    }
   
  };

  const handleMaxChange = (e) => {
    const value = Math.max(e.target.value, minVal + 1);
    setMaxVal(value);
    maxRef.current = value;
    if (onMaxValueChange) {
      onMaxValueChange(maxVal)
    }
    
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: '15px',
      }}
    >
      <div
        style={{
          width: "100%",
          height: "125px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            onChange={handleMinChange}
            style={{
              pointerEvents: "none",
              position: "absolute",
              height: 0,
              width: "100%",
              outline: "none",
              zIndex: 3,
              appearance: "none",
            }}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            onChange={handleMaxChange}
            style={{
              pointerEvents: "none",
              position: "absolute",
              height: 0,
              width: "100%",
              outline: "none",
              zIndex: 4,
              appearance: "none",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ position: "relative", width: "100%" }}>
            <div
              style={{
                position: "absolute",
                borderRadius: "3px",
                height: "5px",
                backgroundColor: "#D48223",
                width: "100%",
                zIndex: 1,
              }}
            />
            <div
              style={{
                position: "absolute",
                borderRadius: "3px",
                height: "5px",
                backgroundColor: "#D48223",
                zIndex: 2,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceSeeker;