import React from "react";

const Modal = ({ confirmOnClick, cancelOnClick, className}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-45 flex items-center justify-center z-50">
      <div className={`bg-white shadow-lg max-w-sm px-6 py-2 ${
                  className 
                }`} >
        <p>Are you sure you want to remove this address?</p>
        <div className="mt-4 mb-2 flex justify-center">
          <button
            className="px-6 text-sm text-gray-700 font-medium"
            onClick={cancelOnClick}
          >
            Cancel
          </button>
          <button
            className="text-red-600 px-6 text-md font-semibold"
            onClick={confirmOnClick}
          >
            Yes, REMOVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
