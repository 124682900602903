import React, { useEffect, useState } from 'react'
import { getPayementOption } from '../../Networking/PaymentApisAction'

const PaymentOption = () => {
    const [paymentOption, setPaymentOption] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState({});

    useEffect(() => {
        getPayementOption().then(response => {
            const data = response.data.data;
            setPaymentOption(data);
        });
    }, []);

    const handlePayment = (id) => {
        setSelectedPayment((prevState) => ({
            ...prevState,
            [id]: !prevState[id] // Toggle the selected state of the payment option
        }));
    };

    return (
        <div className='bg-white px-2 py-2.5'>
            <div className="flex p-2 items-center sticky">
                <p className='text-18px font-jaldi font-semibold uppercase flex h-5 justify-start items-center text-Black-opacity-60% px-10 text-dark-brown'>
                    Payment option
                </p>
            </div>
            <div className="bg-white px-10">
                {paymentOption && paymentOption.map((item, index) => (
                    <div key={index} className='pl-2.5'>
                        <input
                            value={item.id}
                            type="checkbox"
                            id={`paymentOption-${item.id}`}
                            checked
                            onChange={() => handlePayment(item.id)}
                        />
                        <label className='px-3' htmlFor={`paymentOption-${item.id}`}>
                            {item.paymentType}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PaymentOption;
