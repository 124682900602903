import {AxiosConfig} from "./AxiosConfig";

export const cardQuantity = async (data) => {
    return await AxiosConfig.post('/cart/changeQuantity', data);
}

export const removeCart = async (remove) => {
  return await AxiosConfig.post('cart/removeCart', remove)
}

export const getAllItem = async (getItem) => {
  return await AxiosConfig.post('cart/getAllItems', getItem)
}

 