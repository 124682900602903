import React from "react";
import footerlogo from "../assets/footerlogo.png";
import { HomeIcon, PhoneIcon, FacebookIcon, InstagramIcon, TwitterIcon, PinterestIcon } from "../assets/svg/index";

const Footerpage = () => {
  return (
    <>
      <div className="flex md:justify-between bg-dark-brown md:pl-50px md:pr-40px py-8 md:relative md:flex-row flex-col items-center gap-y-6">
        <div className="flex flex-col">
          <img className="w-40 animate-image" src={footerlogo} alt="Not Found" width={185} height={186} />
        </div>

        <div className="flex flex-col justify-center">
          <div className="flex md:flex-row md:justify-center flex-col  gap-y-2 gap-x-20px animate-slide-up">
            <div className="flex flex-col py-1 justify-center items-center lg:items-start lg:justify-start">
              <img src={HomeIcon} alt="Not Found" height={20} width={30} />
            </div>
            <div className="flex flex-col flex-1 text-white gap-y-2">
              <p className="font-Inter font-medium text-20px tracking-wide">
                3/66 3,ST-22 Kuruvilaikadu,
              </p>
              <p className="font-Inter font-medium text-20px tracking-wide">
                Eraviputhurkadai,
              </p>
              <p className="font-Inter font-medium text-20px tracking-wide">
                Kattathurai.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center pb-8 items-center gap-y-1">
          <div className="flex flex-row justify-end gap-x-16px animate-phone">
            <div className="flex flex-col py-5">
              <img src={PhoneIcon} alt="Not Found" />
            </div>
            <div className="flex flex-col text-white font-Inter font-medium text-20px tracking-wide gap-y-2">
              <p>73739 32235</p>
              <p>94880 68189</p>
            </div>
          </div>

          <div className="flex flex-row md:absolute md:bottom-2 md:right-10 gap-x-1 ">
            <button className="hover:bg-blue-500 hover:rounded-sm">
              <img src={FacebookIcon} alt="Not Found" className="animate-social" />
            </button>
            <button className="hover:bg-blue-500">
              <img src={InstagramIcon} alt="Not Found" className="animate-social" />
            </button>

            <button className="hover:bg-blue-500">
              <img src={TwitterIcon} alt="Not Found" className="animate-social" />
            </button>

            <button className="hover:bg-blue-500">
              <img src={PinterestIcon} alt="Not Found" className="animate-social" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footerpage;
