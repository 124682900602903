import { createContext, useState } from "react";


const AddressState = createContext();

const AddressContext = (props) => {
    const [addressList, setAddressList] = useState([]);
    const [getPlaces, setGetPlaces] = useState([]);
    const [addressTypes, setAddressTypes] = useState([]);

    const updateAddress = (updatedAdd) => {
        setAddressList(updatedAdd);
    };

    const updatePlaces = (update) => {
        setGetPlaces(update);
    };

    const updateTypes = (update) => {
        setAddressTypes(update);
    };

    return <AddressState.Provider value={{ addressList, getPlaces, addressTypes, updateAddress, updatePlaces, updateTypes }}>
        {props.children}
    </AddressState.Provider>
};

export { AddressState, AddressContext };