import React, { useContext, useEffect } from 'react';
import { Auth } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const authUserContext = useContext(Auth);
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem('UserKey');
        authUserContext.setCustomerId(0)
        navigate('/');
    }, []);

    return (
        <div>Logout</div>
    );
}

export default Logout;
