export const RatingreviewData = [
    {
        ratings: [
            { value: "5", reviews: 3200 },
            { value: "4", reviews: 1800 },
            { value: "3", reviews: 900 },
            { value: "2", reviews: 400 },
            { value: "1", reviews: 100 }
        ],
        totalReviewer: 6500
    }
];