import React, { useContext, useEffect, useState } from "react";
import el1 from "../assets/el-1.png";
import el2 from "../assets/el-2.png";
import el3 from "../assets/el-3.png";
import el4 from "../assets/el-4.png";
import el5 from "../assets/el-5.png";
import off2 from "../assets/off2.png";
import off3 from "../assets/off3.png";
import lbimg from "../assets/lbimg.png";
import kiwi from "../assets/kiwi.png";
import subimg1 from "../assets/subimg1.png";
import subimg2 from "../assets/subimg2.png";
import dryfruit1 from "../assets/dryfruit1.png";
import dryfruit2 from "../assets/dryfruit2.png";
import dryfruit3 from "../assets/dryfruit3.png";
import dryfruit4 from "../assets/dryfruit4.png";
import dryfruit5 from "../assets/dryfruit5.png";
import dryfruit6 from "../assets/dryfruit6.png";
import {
  caliocn,
  ratingstar,
  subscribemail,
  leftArrow,
  rightArrow,
  eyebrown,
  heartbrown,
  cartbrown,
  linkbrown,
  eyewhite,
  heartwhite,
  cartwhite,
  linkwhite,
  heartblack,
} from "../assets/svg/index";
import CategoryComponent from "./CategoryComponent";
import Loader from "../acscomponents/Loader";
import { getTopSellingProducts } from "../Networking/ProductApisAction";
import { useNavigate } from "react-router-dom";
import { SearchState } from "../Context/SearchContext";

const Dashboard = () => {
  const [bestProducts, setBestProducts] = useState([
    {
      id: 1,
      img: el1,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 2,
      img: el2,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 3,
      img: el3,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 4,
      img: el4,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 5,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 6,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 7,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 8,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 9,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 10,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
  ]);
  const [offerProducts, setOfferProducts] = useState([
    {
      id: 1,
      img: off2,
    },
    {
      id: 2,
      img: off3,
    },
    {
      id: 3,
      img: off2,
    },
  ]);
  const [topSelingBanner, setTopSelingBaner] = useState([
    { id: 1, img: lbimg },
  ]);
  const [topSelingProducts, setTopSellingProducts] = useState([]);

  const [topSelTreProRecAdd, setTopSelTreProAdd] = useState([
    {
      id: 1,
      name: "Top Selling",
      products: [
        {
          id: 1,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: true,
        },
        {
          id: 2,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 3,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
      ],
    },
    {
      id: 2,
      name: "Trending Products",
      products: [
        {
          id: 1,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 2,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: true,
        },
        {
          id: 3,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
      ],
    },
    {
      id: 3,
      name: "Recently Added",
      products: [
        {
          id: 1,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 21,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 3,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: true,
        },
      ],
    },
  ]);
  const [dryFruits, setDryFruits] = useState([
    { id: 1, img: dryfruit1 },
    { id: 2, img: dryfruit2 },
    { id: 3, img: dryfruit3 },
    { id: 4, img: dryfruit4 },
    { id: 5, img: dryfruit5 },
    { id: 6, img: dryfruit6 },
  ]);
  const [hovericons, setHoverIcons] = useState([
    { id: 1, normalIconUrl: eyebrown, hoverIcon: eyewhite },
    { id: 2, normalIconUrl: cartbrown, hoverIcon: cartwhite },
    { id: 3, normalIconUrl: heartbrown, hoverIcon: heartwhite },
    { id: 4, normalIconUrl: linkbrown, hoverIcon: linkwhite },
  ]);
  const [iconBtnShowStatus, setIocnBtnShowStatus] = useState(null);
  const [iconStatus, setIconStatus] = useState(null);
  // const [customerData, setCustomerData] = useState('');
  const [loading, setLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);

  // useEffect(() => {
  //   setCustomerData()
  // }, [])

  const handleIconBtnShowStatus = (id) => {
    setIocnBtnShowStatus(id);
  };
  const handleHideIconBtn = () => {
    setIocnBtnShowStatus(null);
  };
  const handleIconStatus = (id) => {
    setIconStatus(id);
  };
  const handleHideIcon = () => {
    setIconStatus(null);
  };

  useEffect(() => {
    setLoading(true);
    getTopSellingProducts().then((response) => {
      const topSellingData = response.data.data.topSelling.slice(0, 9);
      setTopSellingProducts(topSellingData);
      setLoading(false);
    });
  }, []);

  const navigate = useNavigate();
  const handleProductDetails = (id) => {
    navigate("ProductDetails", { state: { selectedProductId: id } });
  };

  // const handleProductDetails = (selectedProductId) => {
  //   navigate('/ProductDetails', { state: { selectedProductId: selectedProductId, categoryId: categoryId } });
  // };

  const toggleFavourite = (id) => {
    setIsFavourite((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const { searchData, message } = useContext(SearchState);


  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <div className="font-quicksand bg-lightgrey ">
        {message && message.length > 0 ? (
          <div className="px-2 py-2">
            <h3 className="text-20px font-bold text-dark-brown py-2 px-2">
              Search Results: {searchData.length}
            </h3>
            <p className="h-screen flex justify-center text-red text-xl font-semibold">
              {message}
            </p>
          </div>
        ) : (
          <div>
            {searchData && searchData.length > 0 ? (
              <div className="px-2 py-2">
                <h3 className="text-20px font-bold text-dark-brown py-2 px-2">
                  Search Results: {searchData.length}
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 rounded shadow-lg px-1.5 py-1.5">
                  {searchData.map((item) => (
                    <div
                      key={item.id}
                      className="border rounded-md p-4 bg-white relative"
                    >
                      <img
                        src={item.productImage || ""}
                        alt=""
                        className="h-40 w-full object-cover rounded-md"
                      />
                      <img
                        src={
                          isFavourite[item.productId] ? heartbrown : heartblack
                        }
                        className="absolute right-5 top-5 cursor-pointer"
                        onClick={() => toggleFavourite(item.productId)}
                        alt="Favourite icon"
                      />
                      <h3 className="mt-2 font-semibold text-dark-brown">
                        {item.productName}
                      </h3>
                      <p className="text-gray-500">{item.brandName}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                {/* best of electronics */}
                <div className="md:px-8 px-4  bg-white md:pb-20 ">
                  {/* first section */}
                  {/* Category component */}
                  <CategoryComponent />
                  <div className="">
                    <h3 className="text-dark-brown font-bold text-18px">
                      Best Of Electronics
                    </h3>
                    <div className="md:px-8 sm:px-4">
                      <div className="flex mt-3 gap-x-10 overflow-x-scroll horizontal-scroll no-scrollbar">
                        {bestProducts.map((itm) => (
                          <div
                            key={itm.id}
                            className="border border-brown-opacity-20 bg-white flex flex-col items-center justify-center px-6 py-3 rounded-md flex-shrink-0"
                          >
                            <div>
                              <img
                                src={itm.img}
                                alt="product img"
                                className="h-120px w-98px"
                              />
                            </div>
                            <p className="font-semibold text-16px mt-2">
                              {itm.name}
                            </p>
                            <p className="font-semibold text-13px">
                              From &#8377;{itm.price}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* second section */}
                  <div>
                    <div className="flex overflow-x-scroll gap-x-10 mt-10 md:mt-24 horizontal-scroll">
                      {offerProducts.map((itm) => (
                        <div
                          key={itm.id}
                          className="flex-shrink-0 w-full sm:w-auto"
                        >
                          <img
                            src={itm.img}
                            alt="offer product image"
                            className="sm:w-450px sm:h-276px rounded-md"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* beauty toys and gifts */}

                <div className="md:px-8 px-4 py-2 bg-white md:pb-20 pb-10">
                  {/* first section */}
                  <div className="overflow-hidden">
                    <h3 className="text-dark-brown font-bold text-18px">
                      beauty toys and gifts{" "}
                    </h3>
                    <div className="md:px-8 sm:px-4">
                      <div className="flex mt-3 gap-x-10 overflow-x-scroll horizontal-scroll no-scrollbar">
                        {bestProducts.map((itm) => (
                          <div
                            key={itm.id}
                            className="border border-brown-opacity-20 bg-white flex flex-col items-center justify-center px-6 py-3 rounded-md flex-shrink-0"
                          >
                            <div>
                              <img
                                src={itm.img}
                                alt="product img"
                                className="h-120px w-98px"
                              />
                            </div>
                            <p className="font-semibold text-16px mt-2">
                              {itm.name}
                            </p>
                            <p className="font-semibold text-13px">
                              From &#8377;{itm.price}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* second section */}
                  <div>
                    <div className="flex overflow-x-scroll gap-x-10 mt-10 md:mt-24 horizontal-scroll">
                      {offerProducts.map((itm) => (
                        <div
                          key={itm.id}
                          className="flex-shrink-0 w-full sm:w-auto"
                        >
                          <img
                            src={itm.img}
                            alt="offer product image"
                            className="sm:w-450px sm:h-276px rounded-md"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Top selling products */}
                <div className="md:pl-8 lg:pr-8 px-4 md:mt-14 mt-7 md:pb-24 pb-10">
                  {/* header */}
                  <div className="flex justify-between flex-wrap gap-y-3 gap-x-3">
                    <h3 className="text-20px font-bold text-dark-brown">
                      Top Selling Products
                    </h3>
                    <div className="flex gap-x-2 sm:gap-x-4 md:gap-x-8 md:pr-20 flex-wrap gap-y-3">
                      <button className="font-jaldi text-16px">
                        OFFER PRODUCTS
                      </button>
                      <button className="font-jaldi text-16px border border-black px-2 py-1">
                        POPULAR PRODUCTS
                      </button>
                    </div>
                  </div>
                  {/* images container */}
                  <div className="grid md:grid-cols-2 h-full overflow-hidden  p-1.5 md:space-x-4 ">
                    {/* left side */}
                    <div className="col-span-1 h-96 my-4 md:my-0 md:h-[650px] ">
                      {topSelingBanner.map((itm) => (
                        <div key={itm.id} className="flex justify-center">
                          <img
                            src={itm.img}
                            alt="Top selling product banner"
                            className="object-fill h-96 md:h-[650px] w-full"
                          />
                        </div>
                      ))}
                    </div>
                    {/* right side */}
                    <div className="col-span-1  md:pt-2.5 items-start h-[650px] overflow-scroll no-scroll ">
                      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-5 ">
                        {topSelingProducts &&
                          topSelingProducts.map((itm) => (
                            <div key={itm.id} className="p-1 space-x-5">
                              <div                                
                                className="bg-white rounded-md shadow-md overflow-hidden max-h-48  p-2 md:w-40 lg:w-48 "
                              >
                                <div
                                  className="flex justify-center items-center cursor-pointer h-28 md:3 "
                                  onClick={() => handleProductDetails(itm.id)}
                                >
                                  <img
                                    src={itm.productImage}
                                    alt="product image"
                                    className="object-contain h-20 lg:h-24 md:w-full p-1 "
                                  />
                                </div>
                                <div className="grid shadow-inner w-full h-16 border-b-2 p-2 text-center">
                                  <p className="font-semibold text-sm md:text-xs lg:text-md  text-pretty">
                                    {itm.productName}
                                  </p>
                                  <p className="font-semibold text-sm text-dark-brown">
                                    Rs. {itm.discountedPrice} /-
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>


                  </div>
                </div>
                {/* three colomns */}
                <div className="bg-white md:px-8 md:pt-16 pt-10 md:pb-20 pb-10 font-Inter">
                  <div className="flex flex-wrap gap-y-10 ">
                    {topSelTreProRecAdd.map((itmSection) => (
                      <div
                        key={itmSection.id}
                        className="lg:flex-flex-1-30% md:flex-flex-1-50% flex-flex-1-100% px-4"
                      >
                        <div className="border-2 border-lightgrey rounded-md">
                          <div className="py-4 flex items-center justify-between pr-4">
                            <h3 className="pl-6 font-bold text-20px text-dark-brown">
                              {itmSection.name}
                            </h3>
                            <div className="flex gap-x-2">
                              <img
                                src={leftArrow}
                                alt="leftArrow"
                                className="cursor-pointer"
                              />
                              <img
                                src={rightArrow}
                                alt="rightArrow"
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                          <div>
                            {itmSection.products.map((product) => (
                              <div
                                key={product.id}
                                className="border-t flex items-center"
                              >
                                <div className="flex-1 flex justify-center">
                                  <img
                                    src={product.img}
                                    alt="product image"
                                    className="w-108px h-122px"
                                  />
                                </div>
                                <div className="flex-1 py-3">
                                  <h3 className="font-semibold text-20px">
                                    {product.prodName}
                                  </h3>
                                  <h3 className="font-semibold text-18px text-orangered">
                                    Rs.{product.rate}/-
                                  </h3>
                                  <div className="flex gap-x-1 mt-1">
                                    {product.rating ? (
                                      Array.from({
                                        length: product.rating,
                                      }).map((_, i) => (
                                        <img
                                          src={ratingstar}
                                          alt="rating star"
                                          key={i}
                                        />
                                      ))
                                    ) : (
                                      <p>No rating available</p>
                                    )}
                                  </div>
                                  <div
                                    className={` ${product.cartStatus
                                      ? "bg-gold-yellow"
                                      : "bg-dark-brown"
                                      } w-fit flex py-1 px-2 gap-x-2 mt-2 cursor-pointer`}
                                  >
                                    <p
                                      className={`font-quicksand text-10px text-white `}
                                    >
                                      ADD TO CART
                                    </p>
                                    <img src={caliocn} alt="calender icon" />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* subscribe */}
                <div className="relative font-Inter mb-28">
                  <div className="bg-dark-brown mt-24 flex items-center justify-center">
                    <img
                      src={subimg1}
                      alt=""
                      className="lg:w-359px lg:h-359px w-100px h-100px absolute left-0 -top-11"
                    />
                    <div className="flex flex-col justify-center items-center">
                      <p className="font-semibold text-12px text-white mt-9">
                        Subscribe Our newsletter
                      </p>
                      <p className="font-semibold text-20px text-white mt-7">
                        Get 20% Off discount coupon
                      </p>
                      <div className="flex mt-9 mb-14">
                        <input
                          type="text"
                          placeholder="Enter your email"
                          className="text-13px font-semibold px-3 py-1 outline-none"
                        />
                        <div className="flex items-center px-2 gap-x-1  bg-yellow-subscribe py-2">
                          <img
                            src={subscribemail}
                            alt=""
                            height={10}
                            width={15}
                          />
                          <p className="text-13px font-semibold">SUBSCRIBE</p>
                        </div>
                      </div>
                    </div>
                    <img
                      src={subimg2}
                      alt=""
                      className="lg:h-213px lg:w-213px  w-100px h-100px absolute md:right-2 -bottom-7 right-0 md:bottom-0"
                    />
                  </div>
                </div>

                {/* animation grid part */}
                <div className="md:px-8 px-4 bg-lightgrey md:pb-44 pb-24">
                  <div>
                    <h3 className="font-jaldi text-20px font-bold text-dark-brown">
                      Dry Fruits
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                      {dryFruits.map((itm) => (
                        <div key={itm.id} className="">
                          <div
                            className="relative hover:scale-[0.98] transition-all duration-1000"
                            onMouseEnter={() => handleIconBtnShowStatus(itm.id)}
                            onMouseLeave={() => {
                              handleHideIconBtn();
                            }}
                          >
                            <div className="overflow-hidden">
                              <img
                                src={itm.img}
                                alt="product picture"
                                className="h-72 object-cover w-full zoom-image"
                              />
                            </div>

                            <div
                              className={`transition-opacity duration-1000 absolute top-50% left-50% transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-x-1.5 z-10 ${iconBtnShowStatus === itm.id
                                ? "opacity-100"
                                : "opacity-0"
                                }`}
                            >
                              {hovericons.map((icon) => (
                                <div
                                  key={icon.id}
                                  className="p-2 bg-white rounded-full cursor-pointer hover:bg-yellow-subscribe transition-colors duration-1000"
                                  onMouseEnter={() => handleIconStatus(icon.id)}
                                  onMouseLeave={() => {
                                    handleHideIcon();
                                  }}
                                >
                                  <img
                                    src={
                                      iconStatus === icon.id
                                        ? icon.hoverIcon
                                        : icon.normalIconUrl
                                    }
                                    alt="eye icon"
                                    className="h-4"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default Dashboard;
