import React, { useState, useEffect, useContext } from "react";
import {
  SearchIcon,
  Star,
  browncarticon,
  carticon,
  browndeleteicon,
  deleteicon,
} from "../../assets/svg/index";
import {
  getAllfavourited,
  deleteFavourited,
} from "../../Networking/FavouriteApisAction";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import { addToCart } from "../../Networking/ProductApisAction";
import { useNavigate } from "react-router-dom"; 

const Wishlist = () => {
  const [deleteModelShowId, setDeleteModelShowId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteHoverStatus, setDeleteHoverStatus] = useState(null);
  const [cartHoverStatus, setCartHoverStatus] = useState(null);
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [notification, setNotification] = useState("");

  const AuthContextuser = useContext(Auth);
  const navigate = useNavigate(); 

  useEffect(() => {
    fetchFavouriteProducts();
  }, []);

  const fetchFavouriteProducts = () => {
    setLoading(true);
    if(AuthContextuser.customerId !== 0){
      const bodyData = {
        requestId: AuthContextuser.customerId,
        userId: AuthContextuser.customerId,
      };
      getAllfavourited(bodyData)
        .then((response) => {
          const data = response.data.data;
          setFavouriteProducts(data);
        })
        .catch((error) => {
          console.error('Fetch Error:', error);
          setNotification("Failed to fetch favourite products.");
          setTimeout(() => setNotification(""), 3000);
        })
        .finally(() => setLoading(false));
    console.log(bodyData,'bodyData');

    }
  };


  

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = favouriteProducts.filter((product) =>
    product.productCategory.toLowerCase().includes(searchTerm.toLowerCase())
  );
console.log(filteredProducts,'filter');

  const onDeleteClick = () => {
    if (selectedId !== null) {
      const bodyData = {
        requestId: selectedId,
        userId: AuthContextuser.customerId,
      };

      deleteFavourited(bodyData)
        .then(() => {
          fetchFavouriteProducts();
          setDeleteModelShowId(false);
        })
        .catch((error) => {
          console.error('Delete Error:', error);
          setNotification("Failed to delete product.");
          setTimeout(() => setNotification(""), 3000);
        });
    }
  };

  const handleDeleteConfirmation = () => {
    setIsDeleteConfirmed(true);
  };

  useEffect(() => {
    if (isDeleteConfirmed) {
      onDeleteClick();
      setIsDeleteConfirmed(false);
    }
  }, [isDeleteConfirmed]);

  const handleSetDeleteProduct = (productId) => {
    setSelectedId(productId);
    setDeleteModelShowId(productId);
  };

  const handleDeleteCancel = () => {
    setDeleteModelShowId(null);
  };

  const handleAddCart = (productId) => {
    const filteredProduct = favouriteProducts.find((product) => product.id === productId);

    if (!filteredProduct) {
      console.error("Product not found in the wishlist.");
      return;
    }

    const bodyData = {
      userId: AuthContextuser.customerId,
      productId: filteredProduct.id,
      category: filteredProduct.categoryId,
      subCategory: filteredProduct.subCategoryId,
      productCategory: filteredProduct.productCategoryId,
      brand: filteredProduct.brandId,
      color: filteredProduct.colorId,
      unit: filteredProduct.unitId,
      productSize: filteredProduct.productSizeId,
      quantity: 1,
      active: true,
    };

    addToCart(bodyData)
      .then(() => {
        setNotification("Product added to cart successfully!");
        setTimeout(() => setNotification(""), 3000);
        navigate("/Cartpage");
      })
      .catch((error) => {
        console.error('Error adding to cart:', error);
        setNotification("Failed to add product to cart.");
        setTimeout(() => setNotification(""), 3000);
      });
  };

  return (
    <div>
      {loading && (
        <Loader className={loading ? "blur" : "blur-none"} />
      )}
      <div className="lg:px-130px md:px-80px bg-lightgrey min-h-screen">
        <div className="flex flex-col font-Inter md:p-10 gap-y-2px">
          <div className="flex justify-between md:py-5 md:pl-12 md:pr-14 py-4 md:px-4 px-3 bg-dark-brown items-center">
            <h3 className="text-white md:text-16px text-13px font-semibold whitespace-nowrap">
              My Wishlist <span>({favouriteProducts.length})</span>
            </h3>
            <div className="bg-white flex px-2 sm:gap-x-2 rounded-sm py-1">
              <img src={SearchIcon} alt="searchIcon" height={10} width={10} />
              <input
                type="text"
                placeholder="Search.."
                className="outline-none text-12px font-Inter"
                onChange={handleSearchTermChange}
              />
            </div>
          </div>
          {filteredProducts.length > 0 ? (
            <div className="flex flex-col gap-y-2px">
              {filteredProducts.map((product) => (
                <div
                  className="relative flex justify-between md:py-5 md:pl-12 py-2 px-4 bg-white gap-x-2 border-black-opacity-20"
                  key={product.id}
                >
                  <div className="flex md:gap-x-20 gap-x-2">
                    <div className="flex-shrink-0 flex justify-center items-center">
                      <img
                        src={product.productImage}
                        height={17}
                        width={68}
                        alt="product image"
                      />
                    </div>
                    <div className="flex flex-col gap-y-1 md:gap-y-2 items-start">
                      <h3 className="text-15px line-clamp-2 text-start">
                        {product.productCategory}
                      </h3>
                      <div>
                        <div className="flex md:gap-x-4 gap-x-2 flex-wrap">
                          {product.productSize && (
                            <div className="bg-lightgreen flex items-center justify-center rounded-sm px-1 gap-x-1">
                              <div>
                                <img src={Star} alt="star icon" />
                              </div>
                              <p className="font-quicksand font-bold text-emerald-green text-13px">
                                {product.productSize}
                              </p>
                            </div>
                          )}
                          {product.OriginalPrice && (
                            <p className="font-quicksand text-14px">
                              <del className="text-black">
                                Rs. <span>{product.OriginalPrice}</span>
                              </del>
                            </p>
                          )}
                          {product.subCategoryId && (
                            <p className="font-quicksand font-semibold text-greenrating text-14px">
                              {product.subCategoryId}
                              <span>% off</span>
                            </p>
                          )}
                        </div>
                        <div className="mt-1">
                          {product.lastPrice && (
                            <p className="font-quicksand font-bold text-redprice text-16px text-start">
                              RS. <span>{product.lastPrice}</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start justify-start md:pr-10 pl-1 md:pl-6 flex-col flex-shrink-0 gap-y-2">
                    <div className="grid gap-3 py-0.5">
                      <p
                        className="flex gap-2 hover:text-dark-brown cursor-pointer"
                        onClick={() => handleSetDeleteProduct(product.id)}
                      >
                        <img
                          src={deleteHoverStatus === product.id ? browndeleteicon : deleteicon}
                          onMouseEnter={() => setDeleteHoverStatus(product.id)}
                          onMouseLeave={() => setDeleteHoverStatus(null)}
                          alt="Delete Icon"
                        />
                        Remove
                      </p>

                      <p
                        className="flex gap-2 hover:text-dark-brown cursor-pointer"
                        onClick={() => handleAddCart(product.id)}
                      >
                        <img
                          src={cartHoverStatus === product.id ? browncarticon : carticon}
                          onMouseEnter={() => setCartHoverStatus(product.id)}
                          onMouseLeave={() => setCartHoverStatus(null)}
                          alt="Cart Icon"
                        />
                        Move to Cart
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="py-10 text-center">No Items Found</div>
          )}
        </div>

        {deleteModelShowId && (
          <div className="fixed inset-0 flex items-center justify-center bg-black-opacity-50">
            <div className="bg-white p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete this item?</h3>
              <div className="flex justify-end gap-2">
                <button
                  onClick={handleDeleteConfirmation}
                  className="bg-red-600 text-white px-4 py-2 rounded"
                >
                  Delete
                </button>
                <button
                  onClick={handleDeleteCancel}
                  className="bg-gray-600 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {notification && <div className="notification">{notification}</div>}
    </div>
  );
};

export default Wishlist;
