import { AxiosConfig } from "./AxiosConfig";

export const getOrderItems = async (bodyData) => {
    return await AxiosConfig.post('/orders/getOrderItems?orderId=BISOD-1000220240810', bodyData)
}

//place order

export const placeOrder = async (bodyData) => {
    return await AxiosConfig.post('/orders/placeOrder', bodyData)
}
