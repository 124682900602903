import React, { useContext, useEffect, useState } from 'react';
import { getallCategories } from '../Networking/ProductApisAction';
import { ProductContextStates } from '../Context/ProductContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../acscomponents/Loader';
const CategoryComponent = () => {
    //dummy image data
    const productContextStates = useContext(ProductContextStates);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        getallCategories().then(response => {
            const data = response.data.data;
            productContextStates.getAllCategoriesData(data);

        })
        setLoading(false)
    }, [])

    const handleSelectedCategoryId = (selectedCategoryId) => {
        setLoading(true)
        productContextStates.categorySelectedIdData(selectedCategoryId);
        navigate('/ProductList', { state: { selectedCategoryId: selectedCategoryId } })

    }





    return (
        <div className="">

            {/* <h3 className="text-dark-brown font-bold text-18px uppercase">
                Categories
            </h3> */}
            {loading && (
                <Loader className={loading ? "blur" : "blur-none"} />
            )}
            <div className="md:px-8 bg-white py-2  ">
                <div className="flex  space-x-2 md:space-x-5 overflow-x-scroll horizontal-scroll md:horizontal-scroll  cursor-pointer ">
                    {productContextStates.getCategories && productContextStates.getCategories.map((itm) => (
                        <div
                            key={itm.id}
                            className=" bg-white flex flex-col items-center justify-center  md:px-4  rounded-md flex-shrink-0 shadow-inner2 w-[120px] md:w-[150px] md:h-max px-1.5 py-2"
                            onClick={() => handleSelectedCategoryId(itm.id)}
                        >
                            <img
                                src={itm.imageURL}
                                alt="product img"
                                className="w-full h-16  md:h-[65px] md:w-[80px] object-contain hover:scale-110"
                            />

                            <p className="font-extrabold text-sm md:text-15px text-black-opacity-60 font-quicksand mt-2.5 text-center text-wrap md:text-nowrap">{itm.categoryName}</p>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    )
}

export default CategoryComponent

