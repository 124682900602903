import { useContext, useEffect, useState } from 'react';
import { cardQuantity, getAllItem, removeCart } from '../../Networking/CartApiAction';
import { Auth } from '../../Context/AuthContext';
import { CartStates } from '../../Context/CartContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../../acscomponents/Loader';
import { classNames } from '../../constants/Contants';

const Cartpage = () => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const AuthUserContext = useContext(Auth);
  const cartContext = useContext(CartStates);

  useEffect(() => {
    setLoading(true);

    const data = {
      requestId: AuthUserContext.customerId,
      userId: AuthUserContext.customerId
    };
    getAllItem(data).then(res => {
      if (res.data.code === 200) {
        cartContext.updateCart(res.data.data);
        setLoading(false);
      } else if (res.data.code === 204) {
        setErrorMsg(res.data.message);
        setLoading(false);
      }
    });
  }, [AuthUserContext.customerId]);

  const handleAddQuantity = (id) => {
    setLoading(true);

    const data = {
      requestId: id,
      type: 1
    };

    cardQuantity(data).then(res => {
      if (res.data.code === 200) {
        cartContext.updateCart(prev => prev.map(item => item.id === id ? { ...item, quantity: item.quantity + 1 } : item));
        setLoading(false);
      }
    });
  };

  const handleRemoveQuantity = (id) => {
    setLoading(true);

    const data = {
      requestId: id,
      type: 2
    };

    cardQuantity(data).then(res => {
      if (res.data.code === 200) {
        cartContext.updateCart(prev => prev.map(item => item.id === id ? { ...item, quantity: item.quantity - 1 } : item).filter(item => item.quantity > 0));
        setLoading(false);
      }
    });
  };

  const handleRemoveItem = (id) => {
    setLoading(true);

    const bodyData = {
      requestId: id,
      userId: AuthUserContext.customerId
    };

    removeCart(bodyData).then(res => {
      if (res.data.code === 200) {
        cartContext.updateCart(prev => prev.filter(item => item.id !== id));
        setLoading(false);
      }
    });
  };

  const handleCheckout = () => {
    navigate('/Checkoutpage');
  };

  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <div className="h-screen overflow-hidden no-scroll">
        <div className='grid grid-cols-3 gap-3 py-2 px-5 '>
          <div className='col-span-2 '>
            <div className="h-full overflow-y-scroll no-scroll max-h-[90vh] pb-5 ">
              {cartContext?.cartItems && (
                cartContext?.cartItems.map((item) => (
                  <div key={item.id} className='bg-white p-4 mb-4 grid grid-cols-4 gap-2'>
                    <div className='col-span-1 grid items-center justify-center p-3'>
                      <img className="flex-shrink-0 shadow-inner" src={item.productImage} alt="Product Not Found" height={150} width={180} />
                      <div className='px-5 pt-5 flex items-center justify-center'>
                        <button onClick={() => handleRemoveQuantity(item.id)} disabled={item.quantity === 1} className="text-black ps-4 pe-4 border border-black hover:text-white hover:bg-dark-brown font-quicksand font-medium text-13px border-opacity-25">-</button>
                        <p className="text-black ps-4 pe-4 border border-black font-quicksand font-medium text-13px border-x-slate-300 border-opacity-25">{item.quantity}</p>
                        <button onClick={() => handleAddQuantity(item.id)} className="text-black ps-4 pe-4 border border-black hover:text-white hover:bg-dark-brown font-quicksand font-medium text-13px border-opacity-25">+</button>
                      </div>
                    </div>

                    <div className='col-span-2'>
                      <div className="flex flex-col flex-5 ">
                        <div>
                          <p className="text-md font-jaldi font-normal md:text-18px leading-6">{item.productName} <span className='font-bold'>({item.productSize})</span></p>
                          <p className="font-bold py-2 text-bold md:text-18px font-jaldi">{item.weight}</p>
                          <p className='text-20px font-bold text-red'>Rs. {item.quantity * item.discountedPrice}</p> {/* Show total price for this item */}
                          <div className="flex mt-14">
                            <button className="font-jaldi font-normal md:text-19px text-black tracking-wide">SAVE FOR LATER</button>
                            <button onClick={() => handleRemoveItem(item.id)} className="text-dark-brown font-bold ps-10 font-jaldi tracking-wide text-19px">REMOVE</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-span-1'>
                      <p className="opacity-50 font-jaldi md:text-18px font-normal leading-6 text-right pr-26px">Delivery by Thu Apr 25</p>
                    </div>
                  </div>
                ))
              )}
              {errorMsg && <p className="text-xl text-dark-brown font-Inter font-semibold flex justify-center items-center mt-16 uppercase">{errorMsg}</p>}
            </div>
          </div>

          <div className='col-span-1'>
            <div className="flex flex-col bg-white h-fit flex-2 py-3">
              <div>
                <h2 className="font-bold text-md tracking-wide pl-24px pt-3 pb-3 opacity-50 font-jaldi text-18px leading-5">PRICE DETAILS</h2>
                <hr className="border border-black opacity-5" />
                <div className="flex flex-row flex-wrap pl-24px pr-46px pt-20px font-jaldi text-20px text-black text-lg">
                  <div className="flex-1">
                    <p> Price ( {cartContext.cartItems.length} items )</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p>Rs. {cartContext.cartItems.reduce((total, item) => total + (item.quantity * item.discountedPrice), 0)}</p>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap pl-24px pr-46px pt-20px font-jaldi text-20px text-black text-lg">
                  <div className="flex-1">
                    <p>Tax</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p className="pr-5">-</p>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap pl-24px pr-46px pt-20px font-jaldi text-20px text-black text-lg">
                  <div className="flex-1">
                    <p>Discount</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p>Rs. {cartContext.cartItems.reduce((acc, item) => acc + item.quantity * (item.discount || 0), 0)}</p>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap pl-24px pr-46px pt-20px pb-3 font-jaldi text-20px text-black text-lg">
                  <div className="flex-1">
                    <p>Delivery Charges</p>
                  </div>
                  <div className="flex-1 text-right text-green">
                    <p>Free</p>
                  </div>
                </div>
                <hr className="border border-black opacity-5" />
                <div className="flex flex-row flex-wrap pl-24px pr-46px pt-18px pb-3 font-jaldi text-20px text-black text-lg">
                  <div className="flex-1">
                    <p>Total Amount</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p>Rs. {cartContext.cartItems.reduce((total, item) => total + (item.quantity * item.discountedPrice), 0)}</p>
                  </div>
                </div>
                <hr className="border border-black opacity-5" />
                <div className="pl-24px pr-46px pt-3 pb-3  flex justify-end">
                  <button onClick={handleCheckout} className={classNames(`bg-dark-brown text-white p-2 px-4`)}>Place order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cartpage;
