import { createContext, useState } from "react";

const CartStates = createContext();

const CartContext = (props) => {
    const [cartItems, setCartItems] = useState([]);

    const updateCart = (update) => {
        setCartItems(update);
    }

    return <CartStates.Provider value={{ cartItems, updateCart }}>
        {props.children}
    </CartStates.Provider>
}

export { CartStates, CartContext };