import React from "react";

const AddressBox = ({
  addressData,
  editAddress,
  confirmRemoveAddress,
  setAsDefault,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 px-4 gap-5">
      {addressData.map((address) => (
        <div
          key={address.id}
          className={`${
            address.default
              ? "text-yellow border-2 rounded-md border-yellow "
              : "text-black border-2 rounded-md border-yellow border-opacity-30"
          }`}
        >
          {address.default && (
            <p className="text-white p-2 text-14px bg-yellow rounded-t-sm border-yellow border-b-2 border-opacity-30">
              &nbsp;&nbsp;&nbsp;&nbsp;Default
            </p>
          )}
          <div
            className={`pl-6 pt-4 ${
              address.default ? "text-yellow -mt-2" : ""
            }`}
          >
            <h5 className="font-extrabold text-15px mb-2 overflow-hidden text-ellipsis">{address.name}</h5>
            <p className="text-14px overflow-hidden text-ellipsis">{address.addressLine1},</p>
            <p className="text-14px overflow-hidden text-ellipsis">{address.addressLine2},</p>
            <p className="text-14px overflow-hidden text-ellipsis">{address.city},</p>
            <p className="text-14px overflow-hidden text-ellipsis">Phone number: {address.phone}</p>
            <div
              className={`mt-3 mb-2 flex space-x-1 text-13px ${
                address.default ? "mt-5 text-black" : "pt-8 text-yellow"
              }`}
            >
              <button onClick={() => editAddress(address)}>Edit</button>
              <span>|</span>
              <button onClick={() => confirmRemoveAddress(address)}>
                Remove
              </button>
              {!address.default && (
                <>
                  <span>|</span>
                  <button onClick={() => setAsDefault(address.id)}>
                    Set as Default
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddressBox;
