import { AxiosConfig } from "./AxiosConfig";

export const addFavourited = async (bodyData) => {
    return await AxiosConfig.post('/favourite/addFavourite', bodyData)
}

export const deleteFavourited = async (bodyData) => {
    return await AxiosConfig.post('/favourite/deleteFavourite', bodyData)
}

export const checkFavourited = async (bodyData) => {
    return await AxiosConfig.post('/favourite/checkFavourite', bodyData)
}

export const getAllfavourited = async (bodyData) => {
    return await AxiosConfig.post('/favourite/getAllFavourite', bodyData)
}