import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { AxiosConfig } from "../../Networking/AxiosConfig";
import { getAllCoupons } from '../../Networking/CouponApisAction';
// import Navbar from "../Navbar";

const Coupon = () => {
  const [showAllCoupons, setShowAllCoupons] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const tooltipRef = useRef(null);
  const [coupons, setCoupon] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setActiveTooltip(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleTooltip = (index) => {
    setActiveTooltip(activeTooltip === index ? null : index);
  };

  const handleCopyCode = (code, index) => {
    navigator.clipboard.writeText(code).then(
      () => {
        setCopiedIndex(index);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const handleViewMore = () => {
    setShowAllCoupons(true);
  };

  useEffect(() => {
    getAllCoupons().then(response => {
      const data = response.data.data;
      setCoupon(data);
    })
  }, []);

  return (
    <div className="min-h-screen">
      {/* <Navbar /> */}
      <div className="w-full  flex flex-col items-center pt-12 pb-20 bg-gray-200">

        <div className="flex flex-col p-4 w-full max-w-4xl rounded-lg bg-white">
          <div className="flex justify-start w-full">
            <p className="text-dark-brown font-Quicksand font-semibold text-lg">
              Available Offers
            </p>
          </div>

          {coupons && coupons.length > 0 ?
            coupons.slice(0, showAllCoupons ? coupons.length : 3).map((coupon, index) => (
              <div
                key={index}
                className={`relative flex flex-col md:flex-row justify-between border-opacity-50 border-dark-brown border p-4 w-full ${index === 0 ? "rounded-t-md mt-4" : ""}`}
              >
                <div className="flex flex-col pl-2 pt-1 md:pl-4 flex-1">
                  <p className="font-Inter font-semibold text-18px text-dark-brown ml-1">
                    {coupon.couponCode}
                  </p>
                  <p className="text-green font-Inter ml-1 font-semibold pt-1 text-16px md:mt-0">
                    Get Extra {coupon.maximumDiscount} off
                  </p>
                  <p className=" opacity-95 pl-1 font-Inter pt-1 font-normal text-sm">
                    Get extra {coupon.discount} off on {coupon.discount} (Price inclusive of cashback/coupon)
                  </p>
                </div>
                <div className="flex flex-col pr-2 md:pr-4 w-full md:w-auto ml-0 md:ml-20 mt-4 md:mt-0">
                  <button
                    className="w-24 h-7 pt-1  flex justify-center self-center bg-dark-brown font-Inter font-semibold text-sm text-gray-50 rounded-md mt-1"
                    onClick={() => handleCopyCode(coupon.couponCode, index)}
                    disabled={copiedIndex === index}
                  >
                    {copiedIndex === index ? "Copied" : "Copy"}
                  </button>
                  <p className="pt-2 pb-1 font-Inter font-normal flex justify-center text-sm">
                    Valid Till {coupon.endDate}
                  </p>
                  <button
                    className="text-dark-brown text-base font-Inter flex justify-center font-medium cursor-pointer"
                    onClick={() => handleToggleTooltip(index)}
                  >
                    View T & C
                  </button>
                  {activeTooltip === index && (
                    <div
                      ref={tooltipRef}
                      className="absolute bg-white shadow-lg w-full max-w-xs rounded-lg p-4 mt-2 md:mt-0 md:ml-2 z-10"
                    >
                      <ul className="text-sm list-disc pl-4">
                        <li>This offer is valid until stocks last or till the offer ends.</li>
                        <li>Final price is inclusive of the offer.</li>
                        <li>
                          Offer is applicable on select products and brands. In the event the user returns any/all products in the order placed during the offer period, thereby not maintaining the minimum purchase value as required to avail the offer, he/she shall not be eligible.
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))
            : <div className="flex justify-center items-center p-5 font-bold">
              <p>NO DATA FOUND</p>
            </div>
          }

          {coupons && coupons.length > 3 && !showAllCoupons && (
            <button
              onClick={handleViewMore}
              className="flex justify-center font-Inter font-semibold bg-white text-dark-brown border-opacity-50 border-dark-brown border p-4 w-full mt-0"
            >
              View More
            </button>
          )}
        </div>
      </div>
    </div>

  );
};

export default Coupon;
