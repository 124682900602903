import React from 'react';
import firstImage from '../../assets/image 175.png';
import secondImage from '../../assets/image 174.png';

const notifications = [
  {
    id: 1,
    image: firstImage,
    brand: 'Maruti Nandan Fab Women Fit a... ',
    date: '15 May, 2024',
    details: 'More Details >',
  },
  { 
    id: 2,
    image: secondImage,
    brand: 'POCO',
    date: '15 May, 2024',
    details: 'More Details >',
  },
  {
    id: 3,
    image: firstImage,
    brand: 'Maruti Nandan Fab Women Fit a... ',
    date: '15 May, 2024',
    details: 'More Details >',
  },
  {
    id: 4,
    image: secondImage,
    brand: 'POCO',
    date: '15 May, 2024',
    details: 'More Details >',
  },
];

function Notification() {
  return (
    <div className="bg-white ">
      <div className="flex flex-col border-dark-brown border-opacity-40 mx-4 md:mx-20 border rounded-md space-y-4 pb-20 pt-16 shadow">
        <p className="pl-6 md:pl-10 lg:pl-16 font-inter font-medium text-16px text-dark-brown">All Notifications ({notifications.length})</p>
        {notifications.map((notification) => (
          <div key={notification.id} className="flex flex-col md:flex-row md:mx-16 h-auto border-t justify-between items-center pt-4 px-4 md:px-10 space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex-shrink-0">
              <img src={notification.image} alt="Notification" className="max-w-full h-28" />
            </div>
            <div className="flex flex-col items-center hover:to-dark-brown md:items-start hover:text-dark-brown text-center md:text-left flex-grow">
              <p className="text-sm md:text-base font-inter font-normal text-15px ml-5">{notification.brand}</p>
              <p className="pt-2 text-xs md:text-sm font-inter font-normal text-15px opacity-70 ml-5">{notification.date}</p>
            </div>
            <div className="flex-shrink-0 text-center md:text-left">
              <p className="text-dark-brown font-inter font-medium text-13px cursor-pointer w-32">{notification.details}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Notification;
