import React, { useContext, useEffect, useState } from 'react'
import { Auth } from '../../Context/AuthContext';
import { getUserDetails } from '../../Networking/AuthApisActions';
import { useNavigate } from 'react-router-dom';
const LoginCheckoutSection = () => {
  const [loginChange, setLoginChange] = useState(false);
  const [userdetails, setUserdetails] = useState([]);


  const AuthUserContextData = useContext(Auth)
  useEffect(() => {
    if (AuthUserContextData.customerId != 0) {
      const bodyData = {
        requestId: AuthUserContextData.customerId
      }
      getUserDetails(bodyData).then(response => {
        const data = response.data.data
        setUserdetails(data)
      })
    }
  }, [])
  
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate('login')
  }
  return (
    <div className={userdetails.customerId != 0 ? "hidden" : 'block'}>
      <div className="bg-white pb-3">
        <div className="flex bg-dark-brown p-3  items-center ">
          <p className='text-16px font-bold flex h-7 justify-start items-center text-white px-10'>Login & Signup</p>
        </div>
        <div className="grid md:py-5 px-5 py-6 md:px-14 md:grid-cols-2 grid-cols-1">
          <div className="md:col-span-1 space-y-0">
            <div className="">
              <button className='flex font-semibold rounded-sm uppercase justify-center text-white items-center text-nowrap border-spacing-2 border-3 bg-dark-brown h-8 md:px-14 md:w-full w-full p-2 ' type="submit" onClick={handleLogin}>Login</button>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default LoginCheckoutSection
